import React, { Component } from 'react'
class statusPaket extends Component {
    render () {
        switch (this.props.status) {
            case 'active':
                return <span className="badge badge-success">Active</span>;
            case 'non-active':
                return <span style={{backgroundColor:'#6c757d'}} className="badge badge-secondary">Non-Active</span>;
            case 'premium':
                return <span style={{backgroundColor:'#f2550e'}} className="badge badge-secondary">Premium</span>;
            default :
                return <span>-</span>
        }
    }
}

statusPaket.defaultProps = {
    status: ''
}

export default statusPaket;
