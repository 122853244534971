import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { pageNumber } from '../../functions/functionGeneral'
import Swal from "sweetalert2";
import { Loader } from 'react-loaders';
import {App} from './css/app.css'
import moment from 'moment';
import 'moment/locale/id'
import {
    Table, ButtonGroup, Button, FormGroup, Input, Tooltip, Col,Label
} from 'reactstrap';
import {requestData} from "../../helper/request";
import { Link } from "react-router-dom";
import {DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import Paging from "../../components/paging";
import Spin from "antd/es/spin";
import LabelStatus from "./components/statusBlog";
import format from "format-number";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {faEdit, faPlus, faRetweet, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
export class Blog_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataBlog    : [],
            dataImage   : [],
            paging      : 15,
            perPage     : 0,
            photoIndex  : 0,
            pageCount   : 0,
            keyword     : '',
            status      : '',
            idDel       : '',
            currentPage : 0,
            isLoading   : true,
            modal       : false,
            isOpen      : false,
            visible     : false,
        }
        this.toggle = this.toggle.bind(this)
        this.show = this.show.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handlePaging = this.handlePaging.bind(this)
        this.handlePageClick = this.handlePageClick.bind(this)
        this.handleSearchKeyword = this.handleSearchKeyword.bind(this)
        this.deleteUser = this.deleteUser.bind(this)

    }
    toggle() {
        this.setState({
            modal: true
        });
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({currentPage: selected}, () => {
            this.loadData();
        });
    }

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            isLoading   : true,
            dataBlog    : [],
            [name]      : value
        }, () => {
            this.loadData()
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            [name]: value
        })
    }

    handleSearchKeyword(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            keyword: value
        })
    }

    show() {
        Swal.fire({
            title: 'Apa anda Yakin?',
            text: "Jika sudah dihapus maka tidak bisa kembali lagi!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor:'#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteUser()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
        this.setState({
            visible: true
        });
    }

    deleteUser() {

        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Artikel Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                   this.props.request("admin/blog/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    refresh() {
        this.setState({
            paging      : 25,
            keyword     : "",
            status      : "",
            dataBlog    : [],
            isLoading   : true,
        }, () => {
            this.loadData()
        })

    }

    loadData() {
        let formData = {
            id      : this.state.id,
            auth    : this.props.auth,
            keyword : this.state.keyword,
            status  : this.state.status,
            page    : this.state.currentPage + 1,
            paging  : this.state.paging,
        }
        this.props.request("admin/blog/list", formData)
            .then(response => {
                if (response.success) {
                    var dataImage = [];
                    var result = response.data.data;
                    result.map(item =>
                        dataImage.push((item.blog_image != null ? IMG_HOST +'admin/blog/'+ item.blog_image : DEFAULT_IMG)),

                    );
                    this.setState({
                        dataBlog    : result,
                        dataImage   : dataImage,
                        modal       : false,
                        auth        : this.props.auth,
                        isLoading   : false,
                    });
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({auth: newProps.auth})
        }
    }

    render() {
        const {isOpen,photoIndex } = this.state;
        var no = 0  ;
        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }
        return (
            <Fragment>
                <Breadcrumb title="Daftar Blog" parent="Blog" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/blog/add-blog" className="btn btn-info" style={{float: "right"}}><FontAwesomeIcon icon={faPlus} style={{fontSize:12}}/> Tambah</Link>
                            <h5>Daftar Blog</h5>
                        </div>
                        <div className="row" style={{marginLeft: '5px'}}>
                            <div className="col-md-3">
                                <FormGroup style={{marginLeft:11}}>
                                    <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Filter Berdasarkan Judul) </Label>
                                    <Input type="text"
                                           className="mb-2 form-control-sm form-control"
                                           name="keyword"
                                           onChange={this.handleSearchKeyword}
                                           onKeyUp={(event) => {
                                               event.preventDefault()

                                               if (event.which == 13) {
                                                   this.setState({
                                                       isLoading: true,
                                                       dataBlog : []
                                                   }, () => {
                                                       this.loadData()
                                                   });
                                               }
                                           }}
                                           value={this.state.keyword}
                                           placeholder="Search.."/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Filter By Status) </Label>
                                    <Input bsSize="sm" type="select" name="status" style={{width:'inherit',height:'32px'}} value={this.state.status} onChange={this.handlePaging}>
                                        <option value="">- All Status -</option>
                                        <option value="active">Active</option>
                                        <option value="draft">Draft</option>
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Reset Filter) </Label>
                                <div className="btn-actions-pane-right">
                                    <ButtonGroup size="sm">
                                        <Button caret="true" color="dark"
                                                className={"btn-shadow "}
                                                onClick={() => {
                                                    this.refresh();
                                                }}
                                        ><FontAwesomeIcon icon={faRetweet} style={{fontSize:12}}/> Reset</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="card-body">
                            <Table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th className="text-center">Tanggal dibuat</th>
                                    <th className="text-center">Foto</th>
                                    <th className="text-center">Judul</th>
                                    <th width={40}>Status</th>
                                    <th className="text-center" width={100}>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {!this.state.isLoading && this.state.dataBlog.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.dataBlog.map((item, index) => {
                                    no  += 1
                                    return(
                                        <tr key={item.blog_id}>
                                            <th scope="row">
                                                <small>
                                                    {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                </small>
                                            </th>
                                            <td>
                                                {moment(item.blog_create_date).format('lll')}
                                            </td>
                                            <td>
                                                <ButtonGroup>
                                                    {item.blog_image != null ? (
                                                        <Col sm={2}>
                                                            <img onClick={() =>
                                                                this.setState({ isOpen: true, photoIndex: index })
                                                            }
                                                                 width={50}
                                                                 onError={(event)=>{
                                                                     event.target.src = DEFAULT_IMG
                                                                 }}
                                                                 src={item.blog_image != '' ? IMG_HOST +'admin/blog/'+ item.blog_image : DEFAULT_IMG} alt='image'/>
                                                        </Col>
                                                    ) : <img  width={50} src={DEFAULT_IMG} alt="image"/>}

                                                </ButtonGroup>
                                            </td>
                                            <td align="justify">
                                                <small>
                                                    {item.blog_title}
                                                </small>
                                            </td>
                                            <td>
                                                <LabelStatus status={item.blog_status}/>
                                            </td>

                                            <td align="center">
                                                <a href={"/blog/edit-blog/" + item.blog_id}>
                                                    <FontAwesomeIcon icon={faEdit} style={{fontSize:18, marginRight: 4}}/>
                                                </a>
                                                <a onClick={() => {
                                                    this.setState({
                                                        idDel: item.blog_id
                                                    });
                                                    this.deleteUser()
                                                }}>
                                                    <FontAwesomeIcon icon={faTrash} style={{fontSize:18, color: "red"}}/>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {
                                    isOpen ?(
                                        <Lightbox
                                            mainSrc={this.state.dataImage[photoIndex]}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                        />
                                    ) : null
                                }
                                {this.state.isLoading &&
                                <tr>
                                    <td colSpan="6">
                                        <Spin style={{width: '100%'}}  size="small" tip="Mohon Tunggu..."/>
                                    </td>
                                </tr>
                                }

                                </tbody>
                            </Table>
                            <Paging
                                className="float-right"
                                handlePageClick={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                currentPage={this.state.currentPage}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>

        )
    }
}

export default Blog_List
