import React, {Component, Fragment} from 'react';
import {
    Button,
    ButtonGroup,
    Table,
    FormGroup,
    Col,
    Label,
    UncontrolledTooltip,
    Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faBan, faExternalLinkAlt, faCog, faRetweet, faShare, faPlus, faEllipsisH} from '@fortawesome/free-solid-svg-icons'
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders/dist/react-loaders'
import Paging from '../paging'
import moment from 'moment';
import 'moment/locale/id'
import { toast } from "react-toastify";
import style from './components/button.css'
import Modal from './components/Modal'
import LabelStatus from './components/statusVerify'
import LabelPaket from './components/statusPaket'
import { Button1 } from './components/Button/button';
import {MAIN_HOST, DEFAULT_IMG, IMG_HOST, MITRA_HOST} from "../../helper/host";
import {pageNumber} from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import Breadcrumb from '../common/breadcrumb';
import {Link, Redirect} from "react-router-dom";
import Spin from "antd/es/spin";
import {Tooltip} from "antd";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import FormDateRangePicker from "../form/components/DatePicker/Examples/DateRangeFilter";
import List_User from "../Users/List";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {sidebar} from "../common/sidebar_components/sidebar";
import "../form/components/DatePicker/Examples/style.css"
import arrowRight from "../../assets/icon/arrow-right.svg";
import arrowLeft from "../../assets/icon/arrow-left.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import format from "format-number";

export class Lists extends Component {
    constructor(props) {
        super(props);
        var namabulan = ("01 02 03 04 05 06 07 08 09 10 11 12");
        namabulan = namabulan.split(" ");
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        var bulan = lastDay.getMonth();
        var tahun = lastDay.getFullYear();
        this.state = {
            dataCompany             : [],
            dataPaket               : [],
            dataImage               : [],
            dataInvoice             : [],
            dataPayroll             : [],
            modalPerusahaan         : "",
            year: tahun,
            month: namabulan[bulan],
            perPage                 : 0,
            photoIndex              : 0,
            paging                  : 25,
            token                   : "",
            keyword                 : "",
            status                  : '',
            expired                  : '',
            filter_paket            : '',
            new_paket               : '',
            codeDel                 : '',
            statusLangganan         : '',
            perusahaan_foto         : '',
            intervalId              : null,
            add_month              : '',
            created_at              : '',
            updated_at              : '',
            start_created_at        : '',
            end_created_at          : '',
            start                   : '',
            end                     : '',
            perusahaan_id           : '',
            pageCount               : 0,
            currentPage             : 0,
            currentPayrollPage      : 0,
            editInvoiceNominal      : {},
            editInvoiceStatus       : {},
            isLoading               : true,
            modal                   : false,
            modalPaket              : false,
            modalInvoice            : false,
            modalListInvoice        : false,
            idInvoice               : "",
            modalEditData           : false,
            message1                : false,
            message5                : false,
            isOpen                  : false,
            showModal               : false,
            animation               : 'zoom',
            fileList                : [],
            dateFilter              : moment(),
            modalEditExpired        : false,
        }
        this.toggle                     = this.toggle.bind(this);
        this.handlePaging               = this.handlePaging.bind(this);
        this.handleReject               = this.handleReject.bind(this);
        this.handleBlock                = this.handleBlock.bind(this);
        this.handlePageClick            = this.handlePageClick.bind(this);
        this.handlePayrollPageClick     = this.handlePayrollPageClick.bind(this);
        this.handleChange               = this.handleChange.bind(this);
        this.handleChangeInput          = this.handleChangeInput.bind(this);
        this.handleChangePayroll               = this.handleChangePayroll.bind(this);
        this.handleFileChange           = this.handleFileChange.bind(this);
        this.showModal                  = this.showModal.bind(this);
        this.accPaket                   = this.accPaket.bind(this);
        this.handleSearchKeyword        = this.handleSearchKeyword.bind(this);
        this.exportPerusahaan           = this.exportPerusahaan.bind(this);
        this.handleDate                 = this.handleDate.bind(this)
        this.handleTanggal                 = this.handleTanggal.bind(this)
        this.deleteUser                 = this.deleteUser.bind(this)
        this.renewPaket                 = this.renewPaket.bind(this)
        this.updatePaket                 = this.updatePaket.bind(this)
    }

    showModal (){
        Swal.fire({
            title: 'Silahkan Pilih!!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Approve',
            cancelButtonText: 'Tutup',
            allowOutsideClick:true,
            denyButtonText: 'Reject',
            preConfirm: () => {
                this.setState({
                    status : 'active'
                });
                this.handlePublish()
            }

        })
            .then((result) => {
                if (result.isDenied) {
                    this.setState({
                        status : 'reject'
                    })
                    this.handleReject()
                }
            })
    };

    renewPaket() {
        if(!this.state.add_month){
            Swal.fire({
                title: 'Gagal!',
                text: "Pastikan anda telah memilih Bulan",
                icon: 'danger',
                showConfirmButton: false,
                timer: 800,
            })
        }
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin memperbarui Langganan Paket Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.codeDel,
                        month : this.state.add_month
                    }
                    this.props.request("admin/perusahaan/langganan/renew", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    modalPaket: false,
                                    add_month: '',
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    updatePaket() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin mengubah Langganan Paket Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.codeDel,
                        paket_id : this.state.new_paket
                    }
                    this.props.request("admin/perusahaan/langganan/update", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    modalPaket: false,
                                    new_paket: "",
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    createInvoice() {
        if(!this.state.new_paket){
            Swal.fire({
                title: 'Gagal!',
                text: "Pastikan anda telah memilih Paket!",
                icon: 'danger',
                showConfirmButton: false,
                timer: 800,
            })
            return;
        }
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin membuat Invoice Baru untuk Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.perusahaan_id,
                        month : this.state.add_month,
                        paket_id : this.state.new_paket
                    }
                    this.props.request("admin/paket/create-invoice", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    modalInvoice: false,
                                    new_paket: "",
                                    add_month: "",
                                })
                                this.loadData()
                            }else{
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                        });
                }
            })
    }

    accPaket (){
        Swal.fire({
            title: 'Silahkan Pilih!!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Aktifkan',
            cancelButtonText: 'Tutup',
            allowOutsideClick:true,
            denyButtonText: 'Reject',
            preConfirm: () => {
                this.setState({
                    langganan_status : 'success'
                });
                this.handleAcc()
            }

        })
            .then((result) => {
                if (result.isDenied) {
                    this.setState({
                        langganan_status : 'reject'
                    })
                    this.handlePaketReject()
                }
            })
    };

    toggleModal = () => {
        this.setState({
            showModal: ! this.state.showModal
        })
    };

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            dataCompany: [],
            dataInvoice: [],
            dataPayroll: [],
            isLoading: true,
            [name]: value
        },()=>{
            this.loadData()
        })
    }
    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;


        this.setState({
            data: [],
            loading          : true,
            start_created_at : start,
            end_created_at   : end
        }, () => {
            this.loadData()
        });
    }

    handleTanggal(event) {
        const start = event.selectStart;
        const end = event.selectEnd;


        this.setState({
            data: [],
            loading          : true,
            start : start,
            end   : end
        }, () => {
            this.loadData()
        });
    }
    show() {
        Swal.fire({
            title: 'Apa anda Yakin?',
            text: "Jika sudah dihapus maka tidak bisa kembali lagi!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor:'#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteUser()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
        this.setState({
            visible: true
        });
    }

    deleteAllOutlet(){
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Semua Data Outlet Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        modalEditData: false
                    })
                    let form = {
                        id : this.state.perusahaan_id
                    }
                    this.props.request("admin/perusahaan/outlet/delete-all", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    activateAllOutlet(){
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin mengaktifkan Semua Data Karyawan Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        modalEditData: false
                    })
                    let form = {
                        id : this.state.perusahaan_id
                    }
                    this.props.request("admin/perusahaan/karyawan/activate-all", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    resetPasswordAllOutlet(){
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin mereset password Semua Data Karyawan Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    this.setState({
                        modalEditData: false
                    })
                    let form = {
                        id : this.state.perusahaan_id
                    }
                    this.props.request("admin/perusahaan/karyawan/reset-password-all", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    deleteUser() {

        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.idDel
                    }
                    this.props.request("admin/perusahaan/paket/update", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    addToFollowUp() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menambahkan Perusahaan Ini ke daftar Follow Up?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.idDel
                    }
                    this.props.request("admin/perusahaan/bookmark", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    approveEmail(perusahaanId) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin memverifikasi Email Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : perusahaanId
                    }
                    this.props.request("admin/perusahaan/approve-email", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    refresh() {
        this.setState({
            dataCompany         : [],
            keyword             : "",
            filter_paket        : "",
            status              : "",
            expired             : "",
            start_created_at    : '',
            end_created_at      : '',
            start               : '',
            end                 : '',
            isLoading           : true,
        }, () => {
            this.loadData()
        })

    }

    handleFileChange(e) {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            console.warn("img data", e.target.result)
            this.setState({
                s_whitelabel_image: e.target.result
            }, function () {

            });
        }

    }
    handleReject() {
        this.setState({
            loading         : true,
            status          : 'reject',

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                status          : this.state.status,
            };
            this.props.request('admin/perusahaan/verifikasi', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading       : false,
                            status          : '',
                            perusahaan_id   : '',
                        })
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item)
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }
    handlePaketReject() {
        this.setState({
            loading             : true,
            langganan_status    : 'reject',

        },() => {
            let formData = {
                perusahaan_id       : this.state.perusahaan_id,
                langganan_status    : this.state.langganan_status,
            };
            this.props.request('admin/paket/reject', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading           : false,
                            langganan_status    : '',
                            perusahaan_id       : '',
                        })
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item)
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }
    handleBlock() {
        this.setState({
            loading         : true,
            showModal       : ! this.state.showModal,
            status          : 'block',

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                status          : this.state.status,
            };
            this.props.request('admin/perusahaan/verifikasi', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading       : false,
                            status          : '',
                            perusahaan_id   : '',
                        })
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item)
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }

    handlePublish(event) {
        this.setState({
            loading         : true,
            status          : 'active',
            // langganan_status: 'success',
            created_at      : this.state.created_at,

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                status          : this.state.status,
                // langganan_status: this.state.langganan_status,
                created_at      : this.state.created_at,
            };
            this.props.request('admin/perusahaan/verifikasi', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading: false,
                            perusahaan_id: '',
                        });
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item);
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }
    handleAcc(event) {
        this.setState({
            loading         : true,
            langganan_status: 'success',

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                langganan_status: this.state.langganan_status,
            };
            this.props.request('admin/paket/succes', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading: false,
                            perusahaan_id: '',
                        });
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item);
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }

    handleChangeInput(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            [name]: value
        });
    }

    handleChangePayroll(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            loading: true
        }, () => this.loadPayrollData())
    }

    handleSearchKeyword(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            keyword: value
        })
    }

    handlePageClick(data) {

        let selected = data.selected; // zero based index

        this.setState({ currentPage: selected }, () => {
            this.loadData();

        });
    };

    handlePayrollPageClick(data) {

        let selected = data.selected; // zero based index

        this.setState({ currentPayrollPage: selected }, () => {
            this.loadPayrollData();

        });
    };

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    editExpired() {
        let form = {
            perusahaan_id :     this.state.perusahaan_id,
            langganan_expired_date :  this.state.dateFilter.format('YYYY-MM-DD HH:MI:SS')
        }
        this.props.request('admin/perusahaan/edit-exp', form, 'POST')
            .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            title: "Berhasil",
                            text: response.message,
                            icon: 'success',
                            confirmButtonText: 'OK',
                        })
                        this.setState({
                            modalEditExpired: false
                        })
                    } else {
                        Swal.fire({
                            title: "Gagal",
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        })
                    }
                })
    }

    editInvoice() {
        this.setState({
            loading: true
        })
        let form = {
            perusahaan_id : this.state.perusahaan_id,
            id: this.state.editInvoiceID,
            nominal: this.state.editInvoiceNominal,
            status: this.state.editInvoiceStatus
        }
        this.props.request('admin/perusahaan/edit-invoice', form, 'POST')
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: "Berhasil",
                        text: response.message,
                        icon: 'success',
                        confirmButtonText: 'OK',
                    })
                    this.setState({
                        modalListInvoice: false,
                        modalEditInvoice: false,
                        isLoading: true,
                        dataCompany: []
                    })
                    this.loadData();
                } else {
                    Swal.fire({
                        title: "Gagal",
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            })
    }

    loadPayrollData() {
        let formData = {
            perusahaan_id           : this.state.modalPerusahaan,
            page                    : this.state.currentPayrollPage + 1,
            paging                  : 10,
            status                  : this.state.status,
            month: this.state.month,
            year: this.state.year,
        };

        this.props.request('admin/perusahaan/payroll/list  ', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var result = response.data;

                    this.setState({
                            dataPayroll       : result.data,
                            payrollPageCount  : result.last_page,
                            payrollPerPage    : result.per_page,
                        },
                        ()=>{
                            /*const intervalId = setInterval(() => {
                                this.realtimeData()
                            }, 3000);*/

                            // this.setState({ intervalId: intervalId })
                        })
                }
                this.setState({ isLoading: false })
            })

    }

    deletePayrollData() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Data Payroll Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let formData = {
                        id  : this.state.modalPayrollID,
                    };

                    this.props.request('admin/perusahaan/payroll/delete  ', formData, 'POST')
                        .then((response) => {
                            if (response.success) {
                                this.loadPayrollData()
                            }
                            this.setState({ isLoading: false, loading: false })
                        })
                }
            });
    }

    loadData() {
        /*if (this.state.intervalId !== null)
            clearInterval(this.state.intervalId);*/

        let formData = {
            perusahaan_id           : this.state.perusahaan_id,
            page                    : this.state.currentPage + 1,
            paging                  : this.state.paging,
            keyword                 : this.state.keyword,
            status                  : this.state.status,
            expired                  : this.state.expired,
            paket                   : this.state.filter_paket,
            langganan_status        : this.state.langganan_status,
            start_created_at        : this.state.start_created_at,
            end_created_at          : this.state.end_created_at,
            start_tanggal                   : this.state.start,
            end_tanggal                     : this.state.end,
            valueFilterDate: this.state.valueFilterDate,
        };

        this.props.request('admin/perusahaan/list  ', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var dataImage = [];
                    var result = response.data;

                    result.data.map(item =>
                        dataImage.push((item.perusahaan_foto != '' ? IMG_HOST +'admin/perusahaan/'+ item.perusahaan_foto : IMG_HOST + "logo-default-user.png")),
                    );

                    this.setState({
                        dataCompany     : result.data,
                        dataPaket       : response.paket,
                        dataImage       : dataImage,
                        pageCount       : result.last_page,
                        perPage         : result.per_page,
                    },
                        ()=>{
                            /*const intervalId = setInterval(() => {
                                this.realtimeData()
                            }, 3000);*/

                            // this.setState({ intervalId: intervalId })
                        })
                }
                this.setState({ isLoading: false })
            })

    }

    realtimeData() {
        let formData = {
            page                    : this.state.currentPage + 1,
            paging                  : this.state.paging,
            keyword                 : this.state.keyword,
            status                  : this.state.status,
            langganan_status        : this.state.langganan_status,
            start_created_at        : this.state.start_created_at,
            end_created_at          : this.state.end_created_at,
            start_tanggal                   : this.state.start,
            end_tanggal                     : this.state.end,
        };

        this.props.request('admin/perusahaan/list  ', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var dataImage = [];
                    var result = response.data;

                    result.data.map(item =>
                        dataImage.push((item.perusahaan_foto != '' ? IMG_HOST +'admin/perusahaan/'+ item.perusahaan_foto : IMG_HOST + "logo-default-user.png")),

                    );

                    this.setState({
                        dataCompany     : result.data,
                        dataImage       : dataImage,
                        pageCount       : result.last_page,
                        perPage         : result.per_page,
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    /*componentWillUnmount() {
        if (this.state.intervalId !== null)
            clearInterval(this.state.intervalId)
    }*/

    loginPerusahaan(item){
        this.setState({
            token : item.user != null ? item.user.api_token :""
        },()=>{
            let formData = {
                user    : item.user.p_user_id,
            };

            this.props.request('admin/perusahaan/update-token-user', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        window.open(`${MITRA_HOST}/login?token=${response.token}`)
                    }
                    this.setState({ isLoading: false })
                })        })
    }

    exportPerusahaan = () => {
        if (this.state.dataCompany.length != 0){
            this.setState({loadingExport: true,}, async () => {
                var AuthKey = await localStorage.getItem('Presensi-Auth-Key')
                window.open(MAIN_HOST + 'admin/perusahaan/export?token=' + AuthKey +'&keyword='+this.state.keyword + '&status='+this.state.status + '&start_created_at=' + this.state.start_created_at + '&end_created_at=' + this.state.end_created_at + '&start_tanggal=' + this.state.start + '&end_tanggal=' + this.state.end, "_blank")
                this.setState({loadingExport: false})
            })
        }else {
            alert('Data Kosong..!!')
        }
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }
        const {isOpen,photoIndex } = this.state;
        const { showModal, showModalPaket } = this.state;

        var no = 0;
        var no_payroll = 0;

        if(['Admin'].indexOf(this.props.user.level) >= 0) {
            return (
                <Fragment>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <Link to="/company/add-company" className="btn btn-primary ml-2" style={{float: "right"}}><FontAwesomeIcon icon={faPlus} style={{fontSize:12}}/> Tambah</Link>
                                <Button color={"#FFFFFF"}
                                        className="btn-shadow"
                                        disabled={this.state.loadingExport}
                                        style={{float: "right", border: "1px solid #FFF", outline: "#DDDDDD solid 1px" }}
                                        onClick={() => {
                                            this.exportPerusahaan()
                                        }}>
                                    {!this.state.loadingExport &&
                                    <span>
                                         <img src={require('../../assets/icon/arrow-rightup.svg')} />    Export Data
                                        </span>
                                    }
                                </Button>
                                <h5 style={{ color: '#143B5E', fontSize: '24px'}}>Daftar Perusahaan</h5>
                                <p> perusahaan</p>
                            </div>
                            <div className="row">
                                <div className="col-3 mt-3 mr-1 ml-2">
                                    <FormGroup>
                                        <Input type="text"
                                               className="mb-2 form-control-sm form-control bg-image-input-search"
                                               name="keyword"
                                               style={{ backgroundColor: "#F9F9F9", fontSize: "12px", fontWeight: 400, paddingTop: "9px", paddingBottom: "9px" }}
                                               onChange={this.handleSearchKeyword}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()

                                                   if (event.which == 13) {
                                                       this.setState({
                                                           isLoading    : true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}
                                               value={this.state.keyword}
                                               placeholder="        Cari Nama, nomor hp, atau  email ..."/>
                                    </FormGroup>
                                </div>
                                <div className="col-auto mt-3 pr-0 mr-1">
                                    <FormDateRangePicker
                                        placeholder="Tanggal Dibuat"
                                        onChange={this.handlePaging}
                                        onSelect={(event) => {
                                            this.handleDate(event)
                                        }}/>
                                </div>
                                <div className="col-auto mt-3 pl-0">
                                    <FormDateRangePicker
                                        style={{width:'156px', height:"44px"}}
                                        onChange={this.handlePaging}
                                        onSelect={(event) => {
                                            this.handleTanggal(event)
                                        }}/>
                                </div>
                                <div className="col-auto mt-3 pl-0">
                                    <FormGroup>
                                        <Input bsSize="sm" type="select" name="expired" style={{width:'inherit',height:'44px'}} value={this.state.expired} onChange={this.handlePaging}>
                                            <option value="">Filter Expired</option>
                                            <option value="3">3 Hari</option>
                                            <option value="7">7 Hari</option>
                                            <option value="14">14 Hari</option>
                                            <option value="30">30 Hari</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-auto mt-3 pl-0">
                                    <FormGroup>
                                        <Input bsSize="sm" type="select" name="status" style={{width:'77px',height:'44px'}} value={this.state.status} onChange={this.handlePaging}>
                                            <option value="">Status</option>
                                            <option value="active">Active</option>
                                            <option value="pending">Pending</option>
                                            <option value="reject">Reject</option>
                                            <option value="block">Block</option>
                                            <option value="expired">Expired</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-auto mt-3 pl-0">
                                    <FormGroup>
                                        <Input bsSize="sm" type="select" name="filter_paket" style={{width:'77px',height:'44px'}} value={this.state.filter_paket} onChange={this.handlePaging}>
                                            <option value="">Filter Paket</option>
                                            {this.state.dataPaket.map((item, index) => {
                                                return (
                                                    <option value={item.paket_id}>{item.paket_nama}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-auto mt-3 pl-0">
                                    <div className="btn-actions-pane-right">
                                        <ButtonGroup size="sm" style={{width:"44px", height:"44px"}}>
                                            <Button caret="true" color={"#F9F9F9"}
                                                    className={"btn-shadow "}
                                                    onClick={() => {
                                                        this.refresh();
                                                    }}
                                            ><i className="pe-7s-refresh"><img src={require('../../assets/icon/refresh.svg')} /></i></Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="clearfix"></div>
                                <Table className="mb-5">
                                    <thead className='text-table'>
                                        <tr>
                                            <th width={30}>#</th>
                                            <th>Nama</th>
                                            <th>Tanggal Dibuat</th>
                                            <th className="text-center tr-text">Jumlah Karyawan</th>
                                            <th className="text-center tr-text">Terakhir Aktif</th>
                                            <th className="text-center tr-text">Telp/WA</th>
                                            <th className="text-center">Paket Langganan</th>
                                            <th className="text-center" width={30}>Status</th>
                                            <th className="text-center">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {!this.state.isLoading && this.state.dataCompany.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.dataCompany.map((item, index) => {
                                        no += 1
                                        return (
                                            <tr key={index} className="text-column" style={{backgroundColor: (item.langganan != null ? item.langganan.langganan_status : '') ==  'expired' && '#fa8878'}}>
                                                <small>
                                                    <th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</th>
                                                </small>
                                                <td>
                                                        {item.perusahaan_nama}<br/>{item.perusahaan_bidang}
                                                    <small>
                                                        <br/>{item.perusahaan_email}
                                                    </small>
                                                </td>
                                                <td>
                                                        {moment(item.created_at).format('lll')} WIB
                                                </td>
                                                <td className="text-center">
                                                        {item.pengguna != null ? item.pengguna : 0}/{item.karyawan_count}+
                                                </td>
                                                <td className="text-center">
                                                        {item.tanggal != null ? item.tanggal : "-"}
                                                </td>
                                                <td className="text-center">
                                                    <small >
                                                        {item.perusahaan_telp}<br/>
                                                        {item.perusahaan_no_wa ?
                                                            <span className="badge badge-success" style={{fontSize:10}}><FontAwesomeIcon icon={faWhatsapp} style={{fontSize:10}}/>&nbsp;{item.perusahaan_no_wa}</span>
                                                            : "-" }
                                                    </small>

                                                </td>
                                                <td className="text-center">

                                                        {item.langganan != null ?
                                                            <strong>
                                                                {item.langganan.paket_nama}<br/>
                                                                <span>
                                                            <LabelPaket status={item.langganan.langganan_status}/>
                                                        </span>
                                                            </strong>

                                                            :
                                                            <>-</>
                                                        }
                                                        <br/>

                                                        {item.langganan != null ?
                                                            (item.langganan.langganan_status == 'pending' || item.langganan.langganan_status == 'cancel') ?
                                                                <ButtonGroup size="sm">
                                                                    <Button className="btn-shadow"
                                                                            color="warning"
                                                                            name={item.perusahaan_id}
                                                                            onClick={(val) => {
                                                                                this.accPaket()

                                                                                this.setState({
                                                                                    message5: true,
                                                                                    //show: true,
                                                                                    perusahaan_id: item.perusahaan_id,
                                                                                    codeDel: item.perusahaan_id
                                                                                })
                                                                            }}>
                                                                        <FontAwesomeIcon icon={faCog}
                                                                                         style={{fontSize: 12}}/>
                                                                    </Button>
                                                                </ButtonGroup>
                                                                :
                                                                <small>
                                                                    Exp.&nbsp;
                                                                    {moment(item.langganan.langganan_expired_date).format('ll')}
                                                                </small>

                                                            : ''
                                                        }
                                                </td>
                                                <td className="text-center">
                                                    <small>
                                                        <ButtonGroup size="sm">
                                                            <Button id={`more-${item.perusahaan_id}`}
                                                                    className="btn-shadow"
                                                                    color="warning"
                                                                    name={item.perusahaan_id}
                                                                    onClick={(val) => {
                                                                        // this.showModalPaket()
                                                                        this.setState({
                                                                            // message5: true,
                                                                            modalPaket: true,
                                                                            perusahaan_id: item.perusahaan_id,
                                                                            codeDel: item.perusahaan_id
                                                                        })
                                                                    }}>
                                                                <FontAwesomeIcon icon={faEdit} style={{fontSize:12}}/>
                                                            </Button>
                                                        </ButtonGroup>
                                                        <LabelStatus status={item.status}/>
                                                    </small>
                                                </td>
                                                <td className="text-center">
                                                    <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                        <div className={'p-2'}>
                                                            <a onClick={() => {
                                                                this.loginPerusahaan(item)
                                                            }} className={'text-dark mb-2'}>Login Perusahaan</a><br />
                                                            <a href={"/company/edit-company/" + item.perusahaan_id} className={'text-dark mb-2'}>Edit Perusahaan</a><br />
                                                            <a href={"https://wa.me/+62" + (item.perusahaan_no_wa !== null && item.perusahaan_no_wa.length > 0 && item.perusahaan_no_wa.substring(1))} className={'text-dark mb-2'} target={"_blank"}>Hubungi Via WA</a><br />
                                                            <a className={'text-dark mb-2'} onClick={() => this.setState({modalEditExpired:true,
                                                                perusahaan_id: item.perusahaan_id})}>Edit Tanggal Expired</a><br />
                                                            {!item.bookmark &&
                                                            <><a onClick={(val) => {
                                                                this.setState({
                                                                    isLoading: true,
                                                                    idDel: item.perusahaan_id
                                                                });
                                                                this.addToFollowUp()
                                                            }} className={'text-dark mb-2'}>Tambah ke Follow Up</a><br /></>}
                                                            <a onClick={(val) => {
                                                                this.setState({
                                                                    modalInvoice: true,
                                                                    perusahaan_id: item.perusahaan_id,
                                                                })
                                                            }} className={'text-dark mb-2'}>Buat Invoice</a><br />
                                                            <a onClick={() => this.setState({ modalListPayroll:true, modalPerusahaan: item.perusahaan_id, dataPayroll: [] }, () => {this.loadPayrollData()})} className={'text-dark mb-2'}>Daftar Payroll</a><br />
                                                            <a onClick={() => this.setState({ modalListInvoice:true, dataInvoice: item.invoice })} className={'text-dark mb-2'}>Daftar Invoice</a><br />
                                                            <a onClick={(val) => {
                                                                this.setState({
                                                                    modalEditData: true,
                                                                    perusahaan_id: item.perusahaan_id,
                                                                })
                                                            }} className={'text-dark mb-2'}>Ubah/Hapus Data</a><br />
                                                            {!item.verified_at &&
                                                            <>
                                                                <a onClick={() => {
                                                                    this.approveEmail(item.perusahaan_id)
                                                                }} className={'text-warning mb-2'}>Approve
                                                                    Email</a> <br />
                                                            </>
                                                            }
                                                            <a onClick={(val) => {
                                                                this.toggleModal()

                                                                this.setState({
                                                                    showModal       : true,
                                                                    perusahaan_id   : item.perusahaan_id,
                                                                    codeDel         : item.perusahaan_id
                                                                });
                                                            }} className={'text-danger mb-2'}>Reject Perusahaan</a><br/>
                                                            {item.status === 'pending' &&
                                                            <a onClick={() => {
                                                                this.setState({
                                                                    isLoading: true,
                                                                    idDel: item.perusahaan_id
                                                                });
                                                                this.deleteUser()
                                                            }} className={'text-danger'}>
                                                                Hapus Perusahaan
                                                            </a>
                                                            }
                                                        </div>
                                                    }>
                                                        <FontAwesomeIcon icon={faEllipsisH} style={{fontSize:18}}/>
                                                    </Tooltip>
                                                    <small>


                                                    </small>
                                                </td>

                                            </tr>

                                        )})}

                                    {showModal ? (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Block!!
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormGroup>
                                                    <Label> Apakah Anda Yakin</Label>
                                                </FormGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={this.toggleModal}>Tutup</Button1>
                                                <Button1 btnStyle="danger" onClick={() => {
                                                    this.handleBlock()
                                                }}>Block</Button1>
                                            </Modal.Footer>
                                        </Modal>
                                    ) : null
                                    }
                                    {this.state.modalPaket ? (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Pengaturan Paket Perusahaan
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormGroup>
                                                    <Label>Penambahan Masa Aktif Langganan Paket Perusahaan</Label><br/>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Tambahan Masak Aktif) </Label>
                                                    <Input bsSize="sm" type="select" name="add_month" style={{width:'inherit',height:'32px'}} value={this.state.add_month} onChange={this.handlePaging}>
                                                        <option value="">Pilih Bulan</option>
                                                        <option value="1">1 Bulan</option>
                                                        <option value="2">2 Bulan</option>
                                                        <option value="3">3 Bulan</option>
                                                        <option value="4">4 Bulan</option>
                                                        <option value="5">5 Bulan</option>
                                                        <option value="6">6 Bulan</option>
                                                        <option value="7">7 Bulan</option>
                                                        <option value="8">8 Bulan</option>
                                                        <option value="9">9 Bulan</option>
                                                        <option value="10">10 Bulan</option>
                                                        <option value="11">11 Bulan</option>
                                                        <option value="12">12 Bulan</option>
                                                    </Input><br/>
                                                    <button type="button" className="btn btn-primary" onClick={this.renewPaket}>Perbarui Paket</button>
                                                    <br/><br/>
                                                    <Label>Ubah Paket Perusahaan</Label><br/>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Paket Baru) </Label>
                                                    <Input bsSize="sm" type="select" name="new_paket" style={{width:'inherit',height:'32px'}} value={this.state.new_paket} onChange={this.handlePaging}>
                                                        <option value="">Pilih Paket</option>
                                                        {this.state.dataPaket.map((item, index) => {
                                                            return (
                                                                <option value={item.paket_id}>{item.paket_nama}</option>
                                                            )
                                                        })}
                                                    </Input><br/>
                                                    <button type="button" className="btn btn-primary" onClick={this.updatePaket}>Ubah Paket</button>
                                                </FormGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={() => {
                                                    this.setState({
                                                        modalPaket: ! this.state.modalPaket
                                                    })
                                                }}>Tutup</Button1>
                                            </Modal.Footer>
                                        </Modal>
                                    ) : null
                                    }
                                    {this.state.modalInvoice && (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Buat Invoice Baru Perusahaan
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormGroup>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Masa Aktif Paket) </Label>
                                                    <Input bsSize="sm" type="select" name="add_month" style={{width:'inherit',height:'32px'}} value={this.state.add_month} onChange={this.handlePaging}>
                                                        <option value="">Pilih Bulan</option>
                                                        <option value="1">1 Bulan</option>
                                                        <option value="2">2 Bulan</option>
                                                        <option value="3">3 Bulan</option>
                                                        <option value="4">4 Bulan</option>
                                                        <option value="5">5 Bulan</option>
                                                        <option value="6">6 Bulan</option>
                                                        <option value="7">7 Bulan</option>
                                                        <option value="8">8 Bulan</option>
                                                        <option value="9">9 Bulan</option>
                                                        <option value="10">10 Bulan</option>
                                                        <option value="11">11 Bulan</option>
                                                        <option value="12">12 Bulan</option>
                                                    </Input>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Paket pada Invoice) </Label>
                                                    <Input bsSize="sm" type="select" name="new_paket" style={{width:'inherit',height:'32px'}} value={this.state.new_paket} onChange={this.handlePaging}>
                                                        <option value="">Pilih Paket</option>
                                                        {this.state.dataPaket.map((item, index) => {
                                                            return (
                                                                <option value={item.paket_id}>{item.paket_nama}</option>
                                                            )
                                                        })}
                                                    </Input><br/>
                                                    <button type="button" className="btn btn-primary" onClick={() => {this.createInvoice()}}>Buat Invoice Sekarang</button>
                                                </FormGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={() => {
                                                    this.setState({
                                                        modalInvoice: ! this.state.modalInvoice
                                                    })
                                                }}>Tutup</Button1>
                                            </Modal.Footer>
                                        </Modal>
                                    )}
                                    {this.state.modalEditData && (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Ubah atau Hapus Data Perusahaan
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormGroup>
                                                    <label>Karyawan</label><br/>
                                                    <button type="button" className="btn btn-primary" onClick={() => {this.activateAllOutlet()}}>Aktifkan Seluruh Karyawan</button><br/><br/>
                                                    <button type="button" className="btn btn-primary" onClick={() => {this.resetPasswordAllOutlet()}}>Reset Password Seluruh Karyawan</button>
                                                </FormGroup>
                                                <FormGroup>
                                                    <label>Outlet</label><br/>
                                                    <button type="button" className="btn btn-primary" onClick={() => {this.deleteAllOutlet()}}>Hapus Semua Data Outlet</button>
                                                </FormGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={() => {
                                                    this.setState({
                                                        modalEditData: ! this.state.modalEditData
                                                    })
                                                }}>Tutup</Button1>
                                            </Modal.Footer>
                                        </Modal>
                                    )}
                                    {
                                        isOpen ?(
                                            <Lightbox
                                                mainSrc={this.state.dataImage[photoIndex]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                            />
                                        ) : null
                                    }
                                    {this.state.modalEditExpired && (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Ubah Tanggal Expired Langganan
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='d-flex flex-nowrap'>
                                                    <button className='ph-filter-date-left' onClick={() => this.setState({
                                                        dateFilter: moment(this.state.dateFilter).subtract(1, 'days'),
                                                        loading: true
                                                    }, () => this.loadData())}>
                                                        <img src={arrowLeft} alt="" style={{ height: "16px", width: "16px" }} />
                                                    </button>
                                                    <DatePicker
                                                        selected={this.state.valueFilterDate}
                                                        customInput={<button className='ph-filter-date-mid'>{this.state.dateFilter.format('YYYY/MM/DD')}</button>}
                                                        onSelect={(e) =>
                                                            this.setState({
                                                                dateFilter: moment(e),
                                                                valueFilterDate: e,
                                                                loading: true
                                                            }, () => this.loadData())
                                                        }
                                                    />
                                                    <button className='ph-filter-date-right' onClick={() => this.setState({
                                                        dateFilter: moment(this.state.dateFilter).add(1, 'days'),
                                                        loading: true
                                                    }, () => this.loadData())}>
                                                        <img src={arrowRight} alt="" style={{ height: "16px", width: "16px" }} />
                                                    </button>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={() => {
                                                    this.setState({
                                                        modalEditExpired: ! this.state.modalEditExpired
                                                    })
                                                }}>Tutup</Button1>
                                                <button className="btn-primary btn-size" onClick={() => this.editExpired()} >Konfirmasi</button>
                                            </Modal.Footer>
                                        </Modal>
                                    )}
                                    {
                                        isOpen ?(
                                            <Lightbox
                                                mainSrc={this.state.dataImage[photoIndex]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                            />
                                        ) : null
                                    }
                                    {this.state.isLoading &&
                                    <tr>
                                        <td colSpan="10">
                                            <Spin style={{width: '100%'}}  size="small" tip="Mohon Tunggu..."/>
                                        </td>
                                    </tr>
                                    }

                                    </tbody>
                                </Table>
                                {this.state.modalListPayroll && (
                                    <Modal showOverlay={true} showCloseButton={false} size={"lg"}>
                                        <Modal.Header>
                                            <Modal.Title>
                                                <strong>Daftar Payroll</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <FormGroup>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <label style={{ fontSize: '10px' }}>Filter Bulan:</label>
                                                        <select name="month" className="form-control" onChange={this.handleChangePayroll} value={this.state.month}>
                                                            <option value="01">Januari</option>
                                                            <option value="02">Februari</option>
                                                            <option value="03">Maret</option>
                                                            <option value="04">April</option>
                                                            <option value="05">Mei</option>
                                                            <option value="06">Juni</option>
                                                            <option value="07">Juli</option>
                                                            <option value="08">Agustus</option>
                                                            <option value="09">September</option>
                                                            <option value="10">Oktober</option>
                                                            <option value="11">November</option>
                                                            <option value="12">Desember</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label style={{ fontSize: '10px' }}>Filter Tahun:</label>
                                                        <select name="year" className="form-control" onChange={this.handleChangePayroll} value={this.state.year}>
                                                            <option value="2020">2020</option>
                                                            <option value="2021">2021</option>
                                                            <option value="2022">2022</option>
                                                            <option value="2023">2023</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                            <option value="2027">2027</option>
                                                            <option value="2028">2028</option>
                                                            <option value="2029">2029</option>
                                                            <option value="2030">2030</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="clearfix"></div>
                                                    <Table className="mb-5">
                                                        <thead className='text-table'>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Karyawan</th>
                                                            <th className="text-center tr-text">Periode</th>
                                                            <th className="text-center">Tanggal</th>
                                                            <th className="text-center">Waktu Kerja</th>
                                                            <th className="text-center">Gaji</th>
                                                            <th className="text-center">Kompensasi</th>
                                                            <th className="text-center">Potongan</th>
                                                            <th className="text-center">Total/Gaji Akhir</th>
                                                            <th className="text-center" width={30}>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {!this.state.isLoading && this.state.dataPayroll.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.dataPayroll.map((item, index) => {
                                                            no_payroll += 1
                                                            return (
                                                                <tr key={index} className="text-column"
                                                                    style={{backgroundColor: (item.invoice != null ? item.invoice.invoice_status : '') == 'expired' && '#fa8878'}}>
                                                                    <small>
                                                                        <td scope="row">{pageNumber(no_payroll, this.state.payrollPerPage, this.state.currentPage)}</td>
                                                                    </small>
                                                                    <td>{item.karyawan.karyawan_nama}</td>
                                                                    <td>{item.payroll_month + "/" + item.payroll_year}</td>
                                                                    <td>{moment(item.payroll_created_date).format('lll')} WIB</td>
                                                                    <td>{item.payroll_waktu_kerja} {item.payroll_type}</td>
                                                                    <td>{item.payroll_gaji !== null ? format({ prefix: '', integerSeparator: "." })(item.payroll_gaji) : ('-')}</td>
                                                                    <td>{format({ prefix: '', integerSeparator: "." })(item.payroll_kompensasi ? item.payroll_kompensasi : '-')}</td>
                                                                    <td>{format({ prefix: '', integerSeparator: "." })(item.payroll_potongan ? item.payroll_potongan : '-')}</td>
                                                                    <td>{format({ prefix: '', integerSeparator: "." })(item.payroll_total)}</td>
                                                                    <td>{item.payroll_status === 'new' ?
                                                                        <div className={"badge badge-warning"}>New</div>
                                                                        :
                                                                        <div className={"badge badge-success"}>Finish</div>
                                                                    }</td>
                                                                    <td className="text-center">
                                                                        <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                            <div className={'p-2'}>
                                                                                <a onClick={() => {
                                                                                    this.setState({
                                                                                        modalPayrollID: item.payroll_id
                                                                                    }, () => {this.deletePayrollData()})
                                                                                }} rel="noopener noreferrer" className={'text-dark mb-2'}>Hapus</a><br />
                                                                            </div>
                                                                        }>
                                                                            <FontAwesomeIcon icon={faEllipsisH} style={{fontSize:18}}/>
                                                                        </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            )})}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                                <Paging
                                                    className="float-right"
                                                    handlePageClick={this.handlePayrollPageClick}
                                                    pageCount={this.state.payrollPageCount}
                                                    currentPage={this.state.currentPayrollPage}
                                                />
                                            </FormGroup>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button1 onClick={() => {
                                                this.setState({
                                                    modalListPayroll: ! this.state.modalListPayroll
                                                })
                                            }}>Tutup</Button1>
                                        </Modal.Footer>
                                    </Modal>
                                )}
                                {this.state.modalListInvoice && (
                                    <Modal showOverlay={true} showCloseButton={false} size={"lg"}>
                                        <Modal.Header>
                                            <Modal.Title>
                                                <strong>Daftar Invoice</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <FormGroup>
                                                <div className="card-body">
                                                    <div className="clearfix"></div>
                                                    <Table className="mb-5">
                                                        <thead className='text-table'>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Hari/Tanggal</th>
                                                            <th className="text-center tr-text">Nomor Invoice</th>
                                                            <th className="text-center">Paket Langganan</th>
                                                            <th className="text-center">Jumlah Tagihan</th>
                                                            <th className="text-center" width={30}>Status</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                        {!this.state.isLoading && this.state.dataInvoice.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.dataInvoice.map((item, index) => {
                                                            no += 1
                                                            return (
                                                                <tr key={index} className="text-column"
                                                                    style={{backgroundColor: (item.invoice != null ? item.invoice.invoice_status : '') == 'expired' && '#fa8878'}}>
                                                                        <small>
                                                                            <td scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</td>
                                                                        </small>
                                                                        <td>{moment(item.invoice_create_date).format('lll')} WIB</td>
                                                                        <td>{item.invoice_no}</td>
                                                                        <td>{item.paket.paket_nama}</td>
                                                                        <td>Rp {item.invoice_nominal}</td>
                                                                        <td>{item.invoice_status}</td>
                                                                        <td className="text-center">
                                                                            <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                                                <div className={'p-2'}>
                                                                                    <a
                                                                                        className={'text-dark mb-2'}
                                                                                        onClick={() => {
                                                                                            this.setState({
                                                                                                modalEditInvoice: true,
                                                                                                perusahaan_id: item.perusahaan_id,
                                                                                                editInvoiceID: item.invoice_id,
                                                                                                editInvoiceNominal: item.invoice_nominal,
                                                                                                editInvoiceStatus: item.invoice_status
                                                                                            })
                                                                                        }}
                                                                                    >Ubah Invoice</a><br />
                                                                                    <a href={MAIN_HOST + "admin/invoice-pdf?invoice_id=" + item.invoice_id} target="_blank" rel="noopener noreferrer" className={'text-dark mb-2'}>Download PDF</a><br />
                                                                                </div>
                                                                            }>
                                                                                <FontAwesomeIcon icon={faEllipsisH} style={{fontSize:18}}/>
                                                                            </Tooltip>
                                                                    </td>
                                                                </tr>
                                                            )})}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </FormGroup>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button1 onClick={() => {
                                                this.setState({
                                                    modalListInvoice: ! this.state.modalListInvoice
                                                })
                                            }}>Tutup</Button1>
                                        </Modal.Footer>
                                    </Modal>
                                )}
                                {this.state.modalEditInvoice && (
                                    <Modal showOverlay={true} showCloseButton={false} size={"lg"}>
                                        <Modal.Header>
                                            <Modal.Title>
                                                <strong>Ubah Invoice</strong>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <FormGroup>
                                                <div className="card-body">
                                                    <div className="clearfix"></div>
                                                    <div>
                                                        <Label>Nilai Invoice</Label>
                                                        <Input type="number"
                                                               className="mb-2 form-control-sm form-control"
                                                               name="editInvoiceNominal"
                                                               style={{ backgroundColor: "#F9F9F9", fontSize: "12px", fontWeight: 400, paddingTop: "9px", paddingBottom: "9px" }}
                                                               onChange={this.handleChangeInput}
                                                               value={this.state.editInvoiceNominal}
                                                               placeholder="Nilai Invoice"/>
                                                    </div>
                                                    <div>
                                                        <Label>Status Invoice</Label>
                                                        <Input bsSize="sm" type="select" name="editInvoiceStatus" style={{width:'inherit',height:'32px'}} value={this.state.editInvoiceStatus} onChange={this.handleChangeInput}>
                                                            <option value="">Pilih Status</option>
                                                            <option value="pending">Pending</option>
                                                            <option value="expired">Expired</option>
                                                            <option value="success">Success</option>
                                                        </Input>
                                                    </div>
                                                    <div>
                                                        <button className="mt-2 btn-primary btn-size" onClick={() => this.editInvoice()}>Simpan Invoice</button>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button1 onClick={() => {
                                                this.setState({
                                                    modalEditInvoice: !this.state.modalEditInvoice
                                                })
                                            }}>Tutup</Button1>
                                        </Modal.Footer>
                                    </Modal>
                                )}
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>

                        </div>
                    </div>
                </Fragment>
            );
        }else{
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Lists);
