import React, { Component } from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import { connect } from "react-redux"

const AuthRoute = ({ component: Component, auth, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => !auth ? (
                <Redirect to={{
                    pathname: "/",
                    state: {from: props.location}
                }}/>
            ) : (
                <Component {...props} request={(action, data, method = 'GET') => rest.request(action, data, method) } />
            )
            }
        />
    );
};

const mapStateToProps = state => ({
    auth: state.Auth.auth
});

export default connect(mapStateToProps)(AuthRoute)