import React, {Component, Fragment} from 'react';
import {
    Button,
    ButtonGroup,
    Table,
    FormGroup,
    Col,
    Label,
    UncontrolledTooltip,
    Input
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEdit, faBan, faExternalLinkAlt, faCog, faRetweet, faShare, faPlus, faEllipsisH} from '@fortawesome/free-solid-svg-icons'
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons"
import BlockUi from 'react-block-ui';
import { Loader } from 'react-loaders/dist/react-loaders'
import Paging from '../paging'
import moment from 'moment';
import 'moment/locale/id'
import { toast } from "react-toastify";
import style from './components/button.css'
import Modal from './components/Modal'
import LabelStatus from './components/statusVerify'
import LabelPaket from './components/statusPaket'
import { Button1 } from './components/Button/button';
import {MAIN_HOST, DEFAULT_IMG, IMG_HOST, MITRA_HOST} from "../../helper/host";
import {pageNumber} from "../../functions/functionGeneral";
import Swal from "sweetalert2";
import Breadcrumb from '../common/breadcrumb';
import {Link, Redirect} from "react-router-dom";
import Spin from "antd/es/spin";
import {Tooltip} from "antd";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import FormDateRangePicker from "../form/components/DatePicker/Examples/DateRangeFilter";
import List_User from "../Users/List";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {sidebar} from "../common/sidebar_components/sidebar";

export class BookmarkLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCompany             : [],
            dataPaket               : [],
            dataImage               : [],
            perPage                 : 0,
            photoIndex              : 0,
            paging                  : 25,
            token                   : "",
            keyword                 : "",
            status                  : '',
            expired                  : '',
            new_paket               : '',
            codeDel                 : '',
            statusLangganan         : '',
            perusahaan_foto         : '',
            intervalId              : null,
            add_month              : '',
            created_at              : '',
            updated_at              : '',
            start_created_at        : '',
            end_created_at          : '',
            start                   : '',
            end                     : '',
            perusahaan_id           : '',
            pageCount               : 0,
            currentPage             : 0,
            isLoading               : true,
            modal                   : false,
            modalPaket              : false,
            modalInvoice            : false,
            message1                : false,
            message5                : false,
            isOpen                  : false,
            showModal               : false,
            animation               : 'zoom',
            fileList                : []
        }
        this.toggle                     = this.toggle.bind(this);
        this.handlePaging               = this.handlePaging.bind(this);
        this.handleReject               = this.handleReject.bind(this);
        this.handleBlock                = this.handleBlock.bind(this);
        this.handlePageClick            = this.handlePageClick.bind(this);
        this.handleChange               = this.handleChange.bind(this);
        this.handleFileChange           = this.handleFileChange.bind(this);
        this.showModal                  = this.showModal.bind(this);
        this.accPaket                   = this.accPaket.bind(this);
        this.handleSearchKeyword        = this.handleSearchKeyword.bind(this);
        this.exportPerusahaan           = this.exportPerusahaan.bind(this);
        this.handleDate                 = this.handleDate.bind(this)
        this.handleTanggal                 = this.handleTanggal.bind(this)
        this.deleteUser                 = this.deleteUser.bind(this)
        this.renewPaket                 = this.renewPaket.bind(this)
        this.updatePaket                 = this.updatePaket.bind(this)
        this.editBookmark                 = this.editBookmark.bind(this)
    }

    showModal (){
        Swal.fire({
            title: 'Silahkan Pilih!!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Approve',
            cancelButtonText: 'Tutup',
            allowOutsideClick:true,
            denyButtonText: 'Reject',
            preConfirm: () => {
                this.setState({
                    status : 'active'
                });
                this.handlePublish()
            }

        })
            .then((result) => {
                if (result.isDenied) {
                    this.setState({
                        status : 'reject'
                    })
                    this.handleReject()
                }
            })
    };

    renewPaket() {
        if(!this.state.add_month){
            Swal.fire({
                title: 'Gagal!',
                text: "Pastikan anda telah memilih Bulan",
                icon: 'danger',
                showConfirmButton: false,
                timer: 800,
            })
        }
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin memperbarui Langganan Paket Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.codeDel,
                        month : this.state.add_month
                    }
                    this.props.request("admin/perusahaan/langganan/renew", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    modalPaket: false,
                                    add_month: '',
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    updatePaket() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin mengubah Langganan Paket Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.codeDel,
                        paket_id : this.state.new_paket
                    }
                    this.props.request("admin/perusahaan/langganan/update", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    modalPaket: false,
                                    new_paket: "",
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    createInvoice() {
        if(!this.state.new_paket){
            Swal.fire({
                title: 'Gagal!',
                text: "Pastikan anda telah memilih Paket!",
                icon: 'danger',
                showConfirmButton: false,
                timer: 800,
            })
            return;
        }
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin membuat Invoice Baru untuk Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.perusahaan_id,
                        month : this.state.add_month,
                        paket_id : this.state.new_paket
                    }
                    this.props.request("admin/paket/create-invoice", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.setState({
                                    modalInvoice: false,
                                    new_paket: "",
                                    add_month: "",
                                })
                                this.loadData()
                            }else{
                                Swal.fire({
                                    title: 'Gagal!',
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                        });
                }
            })
    }

    accPaket (){
        Swal.fire({
            title: 'Silahkan Pilih!!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Aktifkan',
            cancelButtonText: 'Tutup',
            allowOutsideClick:true,
            denyButtonText: 'Reject',
            preConfirm: () => {
                this.setState({
                    langganan_status : 'success'
                });
                this.handleAcc()
            }

        })
            .then((result) => {
                if (result.isDenied) {
                    this.setState({
                        langganan_status : 'reject'
                    })
                    this.handlePaketReject()
                }
            })
    };

    toggleModal = () => {
        this.setState({
            showModal: ! this.state.showModal
        })
    };

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            dataCompany: [],
            isLoading: true,
            [name]: value
        },()=>{
            this.loadData()
        })
    }
    handleDate(event) {
        const start = event.selectStart;
        const end = event.selectEnd;


        this.setState({
            data: [],
            loading          : true,
            start_created_at : start,
            end_created_at   : end
        }, () => {
            this.loadData()
        });
    }

    handleTanggal(event) {
        const start = event.selectStart;
        const end = event.selectEnd;


        this.setState({
            data: [],
            loading          : true,
            start : start,
            end   : end
        }, () => {
            this.loadData()
        });
    }
    show() {
        Swal.fire({
            title: 'Apa anda Yakin?',
            text: "Jika sudah dihapus maka tidak bisa kembali lagi!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor:'#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteUser()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
        this.setState({
            visible: true
        });
    }

    deleteUser() {

        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.idDel
                    }
                    this.props.request("admin/perusahaan/paket/update", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    deleteFollowUp() {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Perusahaan ini dari daftar Follow Up?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : this.state.idDel
                    }
                    this.props.request("admin/perusahaan/bookmark/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    approveEmail(perusahaanId) {
        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin memverifikasi Email Perusahaan Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        perusahaan_id : perusahaanId
                    }
                    this.props.request("admin/perusahaan/approve-email", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    refresh() {
        this.setState({
            dataCompany         : [],
            keyword             : "",
            status              : "",
            expired             : "",
            start_created_at    : '',
            end_created_at      : '',
            start               : '',
            end                 : '',
            isLoading           : true,
        }, () => {
            this.loadData()
        })

    }

    handleFileChange(e) {
        let files = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            this.setState({
                s_whitelabel_image: e.target.result
            }, function () {

            });
        }

    }
    handleReject() {
        this.setState({
            loading         : true,
            status          : 'reject',

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                status          : this.state.status,
            };
            this.props.request('admin/perusahaan/verifikasi', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading       : false,
                            status          : '',
                            perusahaan_id   : '',
                        })
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item)
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }
    handlePaketReject() {
        this.setState({
            loading             : true,
            langganan_status    : 'reject',

        },() => {
            let formData = {
                perusahaan_id       : this.state.perusahaan_id,
                langganan_status    : this.state.langganan_status,
            };
            this.props.request('admin/paket/reject', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading           : false,
                            langganan_status    : '',
                            perusahaan_id       : '',
                        })
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item)
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }
    handleBlock() {
        this.setState({
            loading         : true,
            showModal       : ! this.state.showModal,
            status          : 'block',

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                status          : this.state.status,
            };
            this.props.request('admin/perusahaan/verifikasi', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading       : false,
                            status          : '',
                            perusahaan_id   : '',
                        })
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item)
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }

    handlePublish(event) {
        this.setState({
            loading         : true,
            status          : 'active',
            // langganan_status: 'success',
            created_at      : this.state.created_at,

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                status          : this.state.status,
                // langganan_status: this.state.langganan_status,
                created_at      : this.state.created_at,
            };
            this.props.request('admin/perusahaan/verifikasi', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading: false,
                            perusahaan_id: '',
                        });
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item);
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }
    handleAcc(event) {
        this.setState({
            loading         : true,
            langganan_status: 'success',

        },() => {
            let formData = {
                perusahaan_id   : this.state.perusahaan_id,
                langganan_status: this.state.langganan_status,
            };
            this.props.request('admin/paket/succes', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        toast['success'](response.message)
                        this.setState({
                            isLoading: false,
                            perusahaan_id: '',
                        });
                        this.loadData()
                    } else {
                        if (Array.isArray(response.message)) {

                            response.message.map(item => {
                                toast['error'](item);
                                return true
                            })
                        } else {
                            toast['error'](response.message)
                        }
                    }
                    this.setState({isLoading: false})
                })})
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            [name]: value
        });
    }

    handleSearchKeyword(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            keyword: value
        })
    }

    handlePageClick(data) {

        let selected = data.selected; // zero based index

        this.setState({ currentPage: selected }, () => {
            this.loadData();

        });
    };

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    loadData() {
        /*if (this.state.intervalId !== null)
            clearInterval(this.state.intervalId);*/

        let formData = {
            perusahaan_id           : this.state.perusahaan_id,
            page                    : this.state.currentPage + 1,
            paging                  : this.state.paging,
            keyword                 : this.state.keyword,
            status                  : this.state.status,
            expired                  : this.state.expired,
            langganan_status        : this.state.langganan_status,
            start_created_at        : this.state.start_created_at,
            end_created_at          : this.state.end_created_at,
            start_tanggal                   : this.state.start,
            end_tanggal                     : this.state.end,
        };

        this.props.request('admin/perusahaan/list-bookmark', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var dataImage = [];
                    var result = response.data;

                    result.data.map(item =>
                        dataImage.push((item.perusahaan_foto != '' ? IMG_HOST +'admin/perusahaan/'+ item.perusahaan_foto : IMG_HOST + "logo-default-user.png")),

                    );

                    this.setState({
                            dataCompany     : result.data,
                            dataPaket       : response.paket,
                            dataImage       : dataImage,
                            pageCount       : result.last_page,
                            perPage         : result.per_page,
                        },
                        ()=>{
                            /*const intervalId = setInterval(() => {
                                this.realtimeData()
                            }, 3000);*/

                            // this.setState({ intervalId: intervalId })
                        })
                }
                this.setState({ isLoading: false })
            })

    }

    editBookmark(event) {
        let formData = {
            id : this.state.idDel,
            note : this.state.currentEditNote,
        };

        this.props.request('admin/perusahaan/bookmark/edit', formData, 'POST')
            .then((response) => {
                if (response.success) {

                }
                this.setState({ isLoading: false, loading: false })
            })

    }

    realtimeData() {
        let formData = {
            page                    : this.state.currentPage + 1,
            paging                  : this.state.paging,
            keyword                 : this.state.keyword,
            status                  : this.state.status,
            langganan_status        : this.state.langganan_status,
            start_created_at        : this.state.start_created_at,
            end_created_at          : this.state.end_created_at,
            start_tanggal                   : this.state.start,
            end_tanggal                     : this.state.end,
        };

        this.props.request('admin/perusahaan/list  ', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var dataImage = [];
                    var result = response.data;

                    result.data.map(item =>
                        dataImage.push((item.perusahaan_foto != '' ? IMG_HOST +'admin/perusahaan/'+ item.perusahaan_foto : IMG_HOST + "logo-default-user.png")),

                    );

                    this.setState({
                        dataCompany     : result.data,
                        dataImage       : dataImage,
                        pageCount       : result.last_page,
                        perPage         : result.per_page,
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    loginPerusahaan(item){
        this.setState({
            token : item.user != null ? item.user.api_token :""
        },()=>{
            let formData = {
                user    : item.user.p_user_id,
            };

            this.props.request('admin/perusahaan/update-token-user', formData, 'POST')
                .then((response) => {
                    if (response.success) {
                        window.open(`${MITRA_HOST}/login?token=${response.token}`)
                    }
                    this.setState({ isLoading: false })
                })        })
    }

    exportPerusahaan = () => {
        if (this.state.dataCompany.length != 0){
            this.setState({loadingExport: true,}, async () => {
                var AuthKey = await localStorage.getItem('Presensi-Auth-Key')
                window.open(MAIN_HOST + 'admin/perusahaan/export?token=' + AuthKey +'&keyword='+this.state.keyword + '&status='+this.state.status + '&start_created_at=' + this.state.start_created_at + '&end_created_a=' + this.state.end_created_at, "_blank")
                this.setState({loadingExport: false})
            })
        }else {
            alert('Data Kosong..!!')
        }
    }
    componentDidMount() {
        this.loadData()

    }

    render() {
        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }
        const {isOpen,photoIndex } = this.state;
        const { showModal, showModalPaket } = this.state;

        var no = 0;

        if(['Admin'].indexOf(this.props.user.level) >= 0) {
            return (
                <Fragment>
                    <Breadcrumb title="Daftar Perusahaan Follow Up" parent="Perusahaan" />
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <Link to="/company/add-company" className="btn btn-primary ml-2" style={{float: "right"}}><FontAwesomeIcon icon={faPlus} style={{fontSize:12}}/> Tambah</Link>
                                <Button color="success"
                                        className={"btn-shadow "}
                                        disabled={this.state.loadingExport}
                                        style={{float: "right"}}
                                        onClick={() => {
                                            this.exportPerusahaan()

                                        }}>
                                    {!this.state.loadingExport &&
                                    <span>
                                            <FontAwesomeIcon icon={faShare} style={{fontSize:12}}/> Export Data
                                        </span>
                                    }
                                </Button>
                                <h5>Daftar Perusahaan</h5>
                            </div>

                            <div className="row" style={{marginLeft: '5px'}}>
                                <div className="col-3">
                                    <FormGroup style={{marginLeft:11}}>
                                        <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Nama, Email, Bidang, No Telepon/WA) </Label>
                                        <Input type="text"
                                               className="mb-2 form-control-sm form-control"
                                               name="keyword"
                                               onChange={this.handleSearchKeyword}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()

                                                   if (event.which == 13) {
                                                       this.setState({
                                                           isLoading    : true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}
                                               value={this.state.keyword}
                                               placeholder="Search.."/>
                                    </FormGroup>
                                </div>
                                <div className="col-2">
                                    <label style={{fontSize: '8px', marginBottom: "1px"}}>(Filter Tanggal Di Buat)</label>
                                    <FormDateRangePicker
                                        style={{width:'100%'}}
                                        onChange={this.handlePaging}
                                        onSelect={(event) => {
                                            this.handleDate(event)
                                        }}/>
                                </div>
                                <div className="col-auto">
                                    <FormGroup>
                                        <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Filter Berdasarkan Status) </Label>
                                        <Input bsSize="sm" type="select" name="status" style={{width:'inherit',height:'32px'}} value={this.state.status} onChange={this.handlePaging}>
                                            <option value="">- All Status -</option>
                                            <option value="active">Active</option>
                                            <option value="pending">Pending</option>
                                            <option value="reject">Reject</option>
                                            <option value="block">Block</option>
                                            <option value="expired">Expired</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-auto">
                                    <FormGroup>
                                        <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Filter Menuju Expired) </Label>
                                        <Input bsSize="sm" type="select" name="expired" style={{width:'inherit',height:'32px'}} value={this.state.expired} onChange={this.handlePaging}>
                                            <option value="">- All Expired -</option>
                                            <option value="3">3 Hari</option>
                                            <option value="7">7 Hari</option>
                                            <option value="14">14 Hari</option>
                                            <option value="30">30 Hari</option>
                                        </Input>
                                    </FormGroup>
                                </div>
                                <div className="col-1">
                                    <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Reset Filter) </Label>
                                    <div className="btn-actions-pane-right">
                                        <ButtonGroup size="sm">
                                            <Button caret="true" color="dark"
                                                    className={"btn-shadow "}
                                                    onClick={() => {
                                                        this.refresh();
                                                    }}
                                            ><i className="pe-7s-refresh"><FontAwesomeIcon icon={faRetweet} style={{fontSize:12}}/></i> Reset</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="clearfix"></div>
                                <Table className="mb-5">
                                    <thead>
                                    <tr>
                                        <th width={30}>#</th>
                                        <th className="text-center">Foto</th>
                                        <th>Nama</th>
                                        <th>Catatan</th>
                                        <th className="text-center">Paket Langganan</th>
                                        <th className="text-center">Telp/WA</th>
                                        <th className="text-center" width={30}>Status Paket</th>
                                        <th className="text-center">Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {!this.state.isLoading && this.state.dataCompany.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.dataCompany.map((item, index) => {
                                        no += 1
                                        return (
                                            <tr key={index} style={{backgroundColor: (item.perusahaan.langganan != null ? item.perusahaan.langganan.langganan_status : '') ==  'expired' && '#fa8878'}}>
                                                <small>
                                                    <th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</th>
                                                </small>
                                                <td className="text-center">
                                                    <small>
                                                        <ButtonGroup>
                                                            {IMG_HOST + item.perusahaan.perusahaan_foto != null ? (
                                                                <Col sm={2} key={index} >
                                                                    <img onClick={() => this.setState({ isOpen: true, photoIndex: index })}
                                                                         width={50}
                                                                         onError={(event)=>{
                                                                             event.target.src = DEFAULT_IMG
                                                                         }}
                                                                         src={item.perusahaan.perusahaan_foto != '' ? IMG_HOST +'admin/perusahaan/'+ item.perusahaan.perusahaan_foto : DEFAULT_IMG} alt='image' />
                                                                </Col>
                                                            ) : <small>{DEFAULT_IMG}</small>}
                                                        </ButtonGroup>
                                                    </small>
                                                </td>
                                                <td>
                                                    <small>
                                                        {item.perusahaan.perusahaan_nama}<br/>{item.perusahaan.perusahaan_bidang}
                                                        <br/>{item.perusahaan.perusahaan_email}
                                                    </small>
                                                </td>
                                                <td>
                                                    <Input type="text"
                                                           id={"note_" + item.bookmark_id}
                                                           name={"note_" + item.bookmark_id}
                                                           className="mb-2 form-control-sm form-control"
                                                           onChange={(event) => {
                                                               this.setState({
                                                                   currentEditNote: event.target.value,
                                                                   idDel    : item.bookmark_id,
                                                               })
                                                           }}
                                                           onKeyUp={(event) => {
                                                               if (event.which == 13) {
                                                                   this.setState({
                                                                       idDel    : item.bookmark_id,
                                                                       isLoading    : true
                                                                   }, () => {
                                                                       this.editBookmark(event)
                                                                   });
                                                               }
                                                               event.preventDefault()
                                                           }}
                                                           defaultValue={item.bookmark_note}
                                                           placeholder="Catatan.."/>
                                                </td>

                                                <td className="text-center">
                                                    <small>
                                                        {item.perusahaan.langganan != null ?
                                                            <strong>
                                                                {item.perusahaan.langganan.paket_nama}<br/>
                                                                <span>
                                                            <LabelPaket status={item.perusahaan.langganan.langganan_status}/>
                                                        </span>
                                                            </strong>

                                                            :
                                                            <small>-</small>
                                                        }
                                                        <br/>

                                                        {item.perusahaan.langganan != null ?
                                                            (item.perusahaan.langganan.langganan_status == 'pending' || item.perusahaan.langganan.langganan_status == 'cancel') ?
                                                                <ButtonGroup size="sm">
                                                                    <Button className="btn-shadow"
                                                                            color="warning"
                                                                            name={item.perusahaan.perusahaan_id}
                                                                            onClick={(val) => {
                                                                                this.accPaket()

                                                                                this.setState({
                                                                                    message5: true,
                                                                                    //show: true,
                                                                                    perusahaan_id: item.perusahaan.perusahaan_id,
                                                                                    codeDel: item.perusahaan.perusahaan_id
                                                                                })
                                                                            }}>
                                                                        <FontAwesomeIcon icon={faCog}
                                                                                         style={{fontSize: 12}}/>
                                                                    </Button>
                                                                </ButtonGroup>
                                                                :
                                                                <small>
                                                                    Exp.&nbsp;
                                                                    {moment(item.perusahaan.langganan.langganan_expired_date).format('ll')}
                                                                </small>

                                                            : ''
                                                        }
                                                    </small>
                                                </td>
                                                <td className="text-center">
                                                    <small >
                                                        {item.perusahaan.perusahaan_telp}<br/>
                                                        {item.perusahaan.perusahaan_no_wa &&
                                                        <span className="badge badge-success" style={{fontSize:10}}><FontAwesomeIcon icon={faWhatsapp} style={{fontSize:10}}/>&nbsp;{item.perusahaan.perusahaan_no_wa}</span>
                                                        }
                                                    </small>

                                                </td>
                                                <td className="text-center">
                                                    <small>
                                                        <ButtonGroup size="sm">
                                                            <Button id={`more-${item.perusahaan.perusahaan_id}`}
                                                                    className="btn-shadow"
                                                                    color="warning"
                                                                    name={item.perusahaan.perusahaan_id}
                                                                    onClick={(val) => {
                                                                        // this.showModalPaket()
                                                                        this.setState({
                                                                            // message5: true,
                                                                            modalPaket: true,
                                                                            perusahaan_id: item.perusahaan.perusahaan_id,
                                                                            codeDel: item.perusahaan.perusahaan_id
                                                                        })
                                                                    }}>
                                                                <FontAwesomeIcon icon={faEdit} style={{fontSize:12}}/>
                                                            </Button>
                                                        </ButtonGroup>
                                                        <LabelStatus status={item.perusahaan.status}/>
                                                    </small>
                                                </td>
                                                <td className="text-center">
                                                    <Tooltip trigger={'click'} placement="bottom" color={'#fff'} title={
                                                        <div className={'p-2'}>
                                                            <a onClick={() => {
                                                                this.loginPerusahaan(item.perusahaan)
                                                            }} className={'text-dark mb-2'}>Login Perusahaan</a><br />
                                                            <a href={"/company/edit-company/" + item.perusahaan.perusahaan_id} className={'text-dark mb-2'}>Edit Perusahaan</a><br />
                                                            <a href={"https://wa.me/+62" + (item.perusahaan.perusahaan_no_wa !== null && item.perusahaan.perusahaan_no_wa.length > 0 && item.perusahaan.perusahaan_no_wa.substring(1))} className={'text-dark mb-2'} target={"_blank"}>Hubungi Via WA</a><br />
                                                            <a onClick={(val) => {
                                                                this.setState({
                                                                    isLoading: true,
                                                                    idDel: item.perusahaan.perusahaan_id
                                                                });
                                                                this.deleteFollowUp()
                                                            }} className={'text-dark mb-2'}>Hapus dari Follow Up</a><br />
                                                            <a onClick={(val) => {
                                                                this.setState({
                                                                    modalInvoice: true,
                                                                    perusahaan_id: item.perusahaan.perusahaan_id,
                                                                })
                                                            }} className={'text-dark mb-2'}>Buat Invoice</a><br />
                                                            {!item.perusahaan.verified_at &&
                                                            <>
                                                                <a onClick={() => {
                                                                    this.approveEmail(item.perusahaan.perusahaan_id)
                                                                }} className={'text-warning mb-2'}>Approve
                                                                    Email</a> <br />
                                                            </>
                                                            }
                                                            <a onClick={(val) => {
                                                                this.toggleModal()

                                                                this.setState({
                                                                    showModal       : true,
                                                                    perusahaan_id   : item.perusahaan.perusahaan_id,
                                                                    codeDel         : item.perusahaan.perusahaan_id
                                                                });
                                                            }} className={'text-danger mb-2'}>Reject Perusahaan</a><br/>
                                                            {item.perusahaan.status === 'pending' &&
                                                            <a onClick={() => {
                                                                this.setState({
                                                                    isLoading: true,
                                                                    idDel: item.perusahaan.perusahaan_id
                                                                });
                                                                this.deleteUser()
                                                            }} className={'text-danger'}>
                                                                Hapus Perusahaan
                                                            </a>
                                                            }
                                                        </div>
                                                    }>
                                                        <FontAwesomeIcon icon={faEllipsisH} style={{fontSize:18}}/>
                                                    </Tooltip>
                                                    <small>


                                                    </small>
                                                </td>

                                            </tr>

                                        )})}

                                    {showModal ? (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Block!!
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormGroup>
                                                    <Label> Apakah Anda Yakin</Label>
                                                </FormGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={this.toggleModal}>Tutup</Button1>
                                                <Button1 btnStyle="danger" onClick={() => {
                                                    this.handleBlock()
                                                }}>Block</Button1>
                                            </Modal.Footer>
                                        </Modal>
                                    ) : null
                                    }
                                    {this.state.modalPaket ? (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Pengaturan Paket Perusahaan
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormGroup>
                                                    <Label>Penambahan Masa Aktif Langganan Paket Perusahaan</Label><br/>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Tambahan Masak Aktif) </Label>
                                                    <Input bsSize="sm" type="select" name="add_month" style={{width:'inherit',height:'32px'}} value={this.state.add_month} onChange={this.handlePaging}>
                                                        <option value="">Pilih Bulan</option>
                                                        <option value="1">1 Bulan</option>
                                                        <option value="2">2 Bulan</option>
                                                        <option value="3">3 Bulan</option>
                                                        <option value="4">4 Bulan</option>
                                                        <option value="5">5 Bulan</option>
                                                        <option value="6">6 Bulan</option>
                                                        <option value="7">7 Bulan</option>
                                                        <option value="8">8 Bulan</option>
                                                        <option value="9">9 Bulan</option>
                                                        <option value="10">10 Bulan</option>
                                                        <option value="11">11 Bulan</option>
                                                        <option value="12">12 Bulan</option>
                                                    </Input><br/>
                                                    <button type="button" className="btn btn-primary" onClick={this.renewPaket}>Perbarui Paket</button>
                                                    <br/><br/>
                                                    <Label>Ubah Paket Perusahaan</Label><br/>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Paket Baru) </Label>
                                                    <Input bsSize="sm" type="select" name="new_paket" style={{width:'inherit',height:'32px'}} value={this.state.new_paket} onChange={this.handlePaging}>
                                                        <option value="">Pilih Paket</option>
                                                        {this.state.dataPaket.map((item, index) => {
                                                            return (
                                                                <option value={item.paket_id}>{item.paket_nama}</option>
                                                            )
                                                        })}
                                                    </Input><br/>
                                                    <button type="button" className="btn btn-primary" onClick={this.updatePaket}>Ubah Paket</button>
                                                </FormGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={() => {
                                                    this.setState({
                                                        modalPaket: ! this.state.modalPaket
                                                    })
                                                }}>Tutup</Button1>
                                            </Modal.Footer>
                                        </Modal>
                                    ) : null
                                    }
                                    {this.state.modalInvoice && (
                                        <Modal showOverlay={true} showCloseButton={false}>
                                            <Modal.Header>
                                                <Modal.Title>
                                                    Buat Invoice Baru Perusahaan
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <FormGroup>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Masa Aktif Paket) </Label>
                                                    <Input bsSize="sm" type="select" name="add_month" style={{width:'inherit',height:'32px'}} value={this.state.add_month} onChange={this.handlePaging}>
                                                        <option value="">Pilih Bulan</option>
                                                        <option value="1">1 Bulan</option>
                                                        <option value="2">2 Bulan</option>
                                                        <option value="3">3 Bulan</option>
                                                        <option value="4">4 Bulan</option>
                                                        <option value="5">5 Bulan</option>
                                                        <option value="6">6 Bulan</option>
                                                        <option value="7">7 Bulan</option>
                                                        <option value="8">8 Bulan</option>
                                                        <option value="9">9 Bulan</option>
                                                        <option value="10">10 Bulan</option>
                                                        <option value="11">11 Bulan</option>
                                                        <option value="12">12 Bulan</option>
                                                    </Input>
                                                    <Label style={{fontSize: "9px", marginBottom: "1px"}}>(Pilih Paket pada Invoice) </Label>
                                                    <Input bsSize="sm" type="select" name="new_paket" style={{width:'inherit',height:'32px'}} value={this.state.new_paket} onChange={this.handlePaging}>
                                                        <option value="">Pilih Paket</option>
                                                        {this.state.dataPaket.map((item, index) => {
                                                            return (
                                                                <option value={item.paket_id}>{item.paket_nama}</option>
                                                            )
                                                        })}
                                                    </Input><br/>
                                                    <button type="button" className="btn btn-primary" onClick={() => {this.createInvoice()}}>Buat Invoice Sekarang</button>
                                                </FormGroup>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button1 onClick={() => {
                                                    this.setState({
                                                        modalInvoice: ! this.state.modalInvoice
                                                    })
                                                }}>Tutup</Button1>
                                            </Modal.Footer>
                                        </Modal>
                                    )}
                                    {
                                        isOpen ?(
                                            <Lightbox
                                                mainSrc={this.state.dataImage[photoIndex]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                            />
                                        ) : null
                                    }
                                    {this.state.isLoading &&
                                    <tr>
                                        <td colSpan="10">
                                            <Spin style={{width: '100%'}}  size="small" tip="Mohon Tunggu..."/>
                                        </td>
                                    </tr>
                                    }

                                    </tbody>
                                </Table>
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>

                        </div>
                    </div>
                </Fragment>
            );
        }else{
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkLists);
