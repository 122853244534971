import React, { Component, Fragment } from "react";
import moment from "moment";

import Breadcrumb from "../common/breadcrumb";
import { IMG_HOST } from "../../helper/host";
import "antd/dist/antd.css";
import LoadingScreen from "../loadingScreen/loading";
import LabelStatus from "./components/statusVerify";
import LabelPaket from "./components/statusPaket";

export class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCompany: [],
      fileList: [],
      Paket: [],
      id: "",
      perusahaan_id: "",
      status: "",
      perusahaan_password: "",
      isLoading: true,
      firstLoading: true,
      loading: false,
      perusahaan_nama: "",
      perusahaan_email: "",
      perusahaan_user: "",
      perusahaan_alamat: "",
      perusahaan_telp: "",
      perusahaan_bidang: "",
    };
    this.handleBackBtnClick = this.handleBackBtnClick.bind(this);
  }

  demoAsyncCall() {
    return new Promise((resolve) => setTimeout(() => resolve(), 2500));
  }

  loadData() {
    let formData = {
      id: this.props.match.params.id,
      auth: this.props.auth,
    };
    this.props.request("admin/perusahaan/detail", formData).then((response) => {
      if (response.success) {
        var result = response.data;
        var paket = response.paket;
        var perusahaan_paket = result.langganan && paket.filter(
          (paket) => paket.paket_id === result.langganan.paket_id
        )[0]
        
        this.setState({
          dataCompany: result,
          Paket: paket,
          modal: false,
          loading: false,
          perusahaan_paket_nama: perusahaan_paket && perusahaan_paket.paket_nama,
          perusahaan_foto: result.perusahaan_foto,
          perusahaan_id: result.perusahaan_id,
          perusahaan_nama: result.perusahaan_nama,
          perusahaan_alamat: result.perusahaan_alamat,
          perusahaan_telp: result.perusahaan_telp,
          perusahaan_bidang: result.perusahaan_bidang,
          perusahaan_email: result.perusahaan_email,
          perusahaan_user: result.perusahaan_user,
          created_at: result.created_at,
          updated_at: result.updated_at,
          langganan_status: result.langganan ? result.langganan.langganan_status : '-',
          langganan_metode_bayar: result.langganan ? result.langganan.langganan_metode_bayar : '-',
          karyawan_count: result.karyawan_count,
          lokasi_count: result.lokasi_count,
          status: result.status,
        });
      }
    });
  }

  componentDidMount() {
    this.loadData();
    this.demoAsyncCall().then(() => this.setState({ firstLoading: false }));
  }
  componentWillReceiveProps(newProps) {
    if (newProps.auth !== this.state.auth) {
      this.setState({ auth: newProps.auth });
    }
  }

  handleBackBtnClick() {
    this.props.history.push("/company/list-company");
  }

  render() {
    const { loading } = this.state;
    const { firstLoading } = this.state;
    if (firstLoading) {
      return <LoadingScreen />;
    }
    return (
      <Fragment>
        <Breadcrumb title="Detail Perusahaan" parentLink="/company/list-company" parent="Perusahaan" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <h4>Detail Perusahaan</h4>
                  <div className="row">
                    <div className="col-xm-1 col-sm-6 col-md-6 col-xl-6">
                      <div className="row">
                        <div className="col-xl-6 col-md-8">Nama Perusahaan</div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.perusahaan_nama}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Alamat Perusahaan
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.perusahaan_alamat}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Nomor Telp. Perusahaan
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.perusahaan_telp}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Bidang Perusahaan
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.perusahaan_bidang}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-8">Paket</div>
                        <div className="col xl-6 col-md-6">
                          :{" "}
                          {this.state.perusahaan_paket_nama}
                          
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-8">Status Paket</div>
                        <div className="col xl-6 col-md-6">
                          :{" "}
                          <LabelPaket
                            status={
                              this.state.langganan_status
                            }
                          />

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-8">Metode Bayar</div>
                        <div className="col xl-6 col-md-6">
                          :{" "}
                          {this.state.langganan_metode_bayar}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Pengguna Perusahaan
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.perusahaan_user}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Email Perusahaan
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.perusahaan_email}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Jumlah Karyawan
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.karyawan_count}{" "}
                          Karyawan
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Jumlah Lokasi
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {this.state.lokasi_count}{" "}
                          Lokasi
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Tanggal Dibuat
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {moment(this.state.created_at).format("lll")}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Tanggal Diperbarui
                        </div>
                        <div className="col xl-6 col-md-6">
                          : {moment(this.state.updated_at).format("lll")}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-md-8">
                          Status Perusahaan
                        </div>
                        <div className="col xl-6 col-md-6">
                          : <LabelStatus status={this.state.status} />
                        </div>
                      </div>
                    </div>
                    <div className="col-xm-1 col-sm-6 col-md-6 col-xl-6">
                      <div className="row">
                        <div className="col xl-12 col-md-12 d-flex justify-content-end">
                          <div className="d-flex flex-column justify-content-center">
                            <div>Foto Perusahaan</div>
                            <img
                              style={{ marginTop: "20px" }}
                              className="img-fluid company-image"
                              src={
                                this.state.perusahaan_foto
                                  ? IMG_HOST +
                                    "admin/perusahaan/" +
                                    this.state.perusahaan_foto
                                  : IMG_HOST + "logo-default-user.png"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pull-right">
                    <button
                      onClick={this.handleBackBtnClick}
                      type="button"
                      className="btn btn-primary"
                    >
                      <span>Kembali</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Detail;
