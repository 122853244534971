import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import {Row} from 'reactstrap';
import Swal from "sweetalert2";
import {AvForm, AvGroup, AvInput, AvRadioGroup } from "availity-reactstrap-validation";
import { IMG_HOST } from "../../helper/host";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import customToast from "../../notification/customToast";
import LoadingScreen from '../loadingScreen/loading';
import CKEditor from "react-ckeditor-component";

export class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataBlog: [],
            fileList: [],
            image: '',
            title: '',
            desc: '',
            short: '',
            keyword: '',
            status: '',
            isLoading: true,
            firstLoading: true,
            loading: false,
            auth: this.props.auth,
            disabledButton: false,
        }

        this.handleChangePrice = this.handleChangePrice.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }
    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            desc: newContent
        })
    };
    handleChangePrice(event, maskedvalue) {
        const target = event.target;
        const name = target.name;

        this.setState({ [name]: maskedvalue });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }



    handleSubmit = () => {
        this.setState({
            loading: true,
        })
        let form = {
            id: this.state.dataBlog.blog_id,
            title: this.state.title,
            desc: this.state.desc,
            status: this.state.status,
            short: this.state.short,
            keyword: this.state.keyword,
            image: this.state.image,
        }
        this.props.request("admin/blog/update", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: true,
                        title: '',
                        desc: '',
                        short: '',
                        keyword: '',
                        image: '',
                        fileList: [],

                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                    this.props.history.push('/blog/list-blog')
                } else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }

    demoAsyncCall() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2500));
    }

    loadData() {
        let formData = {
            id: this.props.match.params.id,
            auth: this.props.auth,

        }
        this.props.request("admin/blog/detail", formData)
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        dataBlog: result,
                        fileList: [
                            {
                                uid: "-1",
                                name: result.blog_image,
                                status: "done",
                                url: IMG_HOST + 'admin/blog/' + result.blog_image
                            }
                        ],
                        modal: false,
                        auth: this.props.auth,
                        isLoading: false,
                        loading: false,
                        title: result.blog_title,
                        desc: result.blog_desc,
                        status: result.blog_status,
                        short: result.blog_short_desc,
                        keyword: result.blog_keyword,
                    });
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.demoAsyncCall().then(() => this.setState({ firstLoading: false }));
    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }



    render() {
        const { loading } = this.state;
        const { firstLoading } = this.state;
        if (firstLoading) {
            return <LoadingScreen />;
        }

        return (
            <Fragment>
                <Breadcrumb title="Edit Blog" parent="Blog" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <AvForm className="needs-validation user-add" noValidate="">
                                        <h4>Edit Blog</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Foto Blog</label>
                                            <div className="col xl-8 col-md-7">
                                                <ImgCrop
                                                    useRatio={true}
                                                    width={1000}
                                                    height={667}>
                                                    <Upload action="http://localhost:8000/"
                                                        listType="picture-card"
                                                        style={{ width: '200px !important' }}
                                                        fileList={this.state.fileList}
                                                        onChange={(file) => {
                                                            this.setState({ fileList: file.fileList })

                                                            if (file.fileList.length > 0) {
                                                                let files = file.file.originFileObj
                                                                let reader = new FileReader()

                                                                reader.readAsDataURL(files)
                                                                reader.onloadend = () => {
                                                                    this.setState({
                                                                        image: {
                                                                            name: file.file.name,
                                                                            file: reader.result
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                this.setState({ image: [] })
                                                            }
                                                        }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null : "+ Add image"}
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Judul</label>
                                            <input name="title" className="form-control col-xl-8 col-md-7" placeholder="Judul..."
                                                type="text" required="" onChange={this.handleChange} value={this.state.title} />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Deskripsi Singkat</label>
                                            <input name="short" className="form-control col-xl-8 col-md-7" placeholder="Deskripsi Singkat.."
                                                type="text" required="" onChange={this.handleChange} value={this.state.short} />
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Status Blog</label>
                                            <AvGroup style={{ marginBottom: '10px', marginTop: '10px' }}>
                                                <div className="col-md-12" style={{ padding: "inherit" }}>
                                                    <AvRadioGroup inline name="status">
                                                        <Row style={{ marginLeft: '1px' }}>
                                                            <AvInput
                                                                id="active"
                                                                style={{ marginLeft: '5px' }}
                                                                type="radio"
                                                                name="status"
                                                                checked={this.state.status === 'active'}
                                                                onChange={this.handleChange}
                                                                value="active"
                                                            /><span style={{ marginLeft: '25px' }} className="badge badge-success">Active</span>
                                                        </Row>
                                                        <br />
                                                        <Row style={{ marginLeft: '1px' }}>
                                                            <AvInput
                                                                style={{ marginLeft: '5px' }}
                                                                id="draft"
                                                                type="radio"
                                                                name="status"
                                                                checked={this.state.status === 'draft'}
                                                                onChange={this.handleChange}
                                                                value="draft"
                                                            /><span style={{ marginLeft: '25px' }} className="badge badge-warning">Draft</span>
                                                        </Row>
                                                    </AvRadioGroup>
                                                </div>
                                            </AvGroup>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Deskripsi</label>
                                            <div>
                                                <CKEditor
                                                    content={this.state.desc}
                                                    activeClass="p10"
                                                    events={{
                                                        blur: this.onBlur,
                                                        afterPaste: this.afterPaste,
                                                        change: this.onEditorStateChange
                                                    }}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">Keyword <small>(contoh: "absensi, digital, teknologi")</small></label>
                                            <input name="keyword" className="form-control col-xl-8 col-md-7" placeholder="Deskripsi Singkat.."
                                                type="text" value={this.state.keyword} onChange={this.handleChange} />
                                        </div>
                                    </AvForm>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Simpan</span>}
                                            {!loading && <span>Simpan</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit
