import React, { Component, Fragment } from 'react'
import { Link, Redirect } from 'react-router-dom';
import Slider from 'react-slick';
import stats from '../../assets/images/dashboard/stats.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import Swal from "sweetalert2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import './password.css'
import backgroundlogin from "./background.jpg";
import cardleft from "./illustration.svg";
import lgpresensi from "./logo-presensi.svg";

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth                    : this.props.auth,
            disabledButton          : false,
            iconRevealPassword      : false,
            activeShow              : true,
            confirm                 : false,
            emaillogin              : "",
            passwordlogin           : "",
            user_otp                : "",
            startDate               : new Date()
        }
        this.passwordOneRef     = React.createRef();
        this.iconRevealPassword = React.createRef();
        this.handleChange       = this.handleChange.bind(this);
    }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }
    handleChange (event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    submitLogin = () => {
        this.setState({
            disabledButton: true
        })

        let form = {
            email: this.state.emaillogin,
            password: this.state.passwordlogin,
        }

        this.props.request("admin/req_code", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        confirm: true
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            });

    }


    togglePassword = event =>{
        this.setState({
            isRevealPassword : !this.state.isRevealPassword
        })
    }

    submitAuth = () => {
        this.setState({
            disabledButton: true
        })

        let form = {
            email   : this.state.emaillogin,
            password: this.state.passwordlogin,
            user_otp: this.state.user_otp,
        }

        this.props.request("admin/login", form)
            .then((response) => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        showConfirmButton: false,
                        icon: 'success',
                        timer: 800
                    })
                    this.props.set_auth(response.api_token, response.data);
                }
                else{
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                this.setState({
                    disabledButton: false
                })
            })
    }

    componentDidMount() {
        this.setState({ auth: this.props.auth })
    }

    componentWillReceiveProps(newProps) {
        if(newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }

    render() {

        const {isRevealPassword} = this.state;

        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false
        };

        if(this.state.auth) {
            var { location } = this.props

            if(typeof location.state !== 'undefined' && location.state.from.pathname !== '/login')
                var redirect = location.state.from.pathname
            else
                var redirect = '/dashboard'

            return <Redirect to={ redirect }/>
        }
        return (
            <Fragment>
                <div className="page-wrapper" style={{ backgroundImage: `url(${backgroundlogin})`, backgroundRepeat: `no-repeat`, backgroundSize: `cover` }}>
                        <div className="authentication-box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-5 p-0 card-left">
                                        {/*<div className="card bg-white">*/}
                                        <div className="svg-icon2">
                                            <img src={cardleft} style={{ height: "auto", width: "100%" }} />
                                        </div>
                                        {/*</div>*/}
                                    </div>
                                    <div className="col-md-7 p-0 card-right">
                                        <div className="card tab2-card">
                                            <div className="card-body" style={{ borderStyle: "solid", borderWidth: '1px', borderColor: '#e0e0e0', borderRadius: '20px' }}>
                                                <div >
                                                    <div className="card-header">
                                                        <div className="svg-icon3">
                                                            <img src={lgpresensi}/>
                                                        </div>
                                                    </div>
                                                    {!this.state.confirm ?
                                                        <form className="form-horizontal auth-form">
                                                            <div className="form-group">
                                                                <input required="" name="emaillogin" onChange={this.handleChange} type="email"
                                                                       className="form-control" placeholder="Email User..."/>
                                                            </div>
                                                            <div className="form-group">
                                                                <div style={{'position' : 'relative'}}>
                                                                    <input required=""
                                                                           name="passwordlogin"
                                                                           value={this.state.passwordlogin}
                                                                           onChange={this.handleChange}
                                                                           onKeyUp={(event) => {
                                                                               event.preventDefault();

                                                                               if (event.which == 13) {
                                                                                   this.setState({
                                                                                       email        : this.state.emaillogin,
                                                                                       password     : this.state.passwordlogin,
                                                                                       isLoading    : true
                                                                                   }, () => {
                                                                                       this.submitLogin()
                                                                                   });
                                                                               }
                                                                           }}
                                                                           type={isRevealPassword ? "text" : "password"}
                                                                           ref={this.passwordOneRef}
                                                                           className="form-control"
                                                                           placeholder="Password User..."/>
                                                                    <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
                                                                    <span>
                                                                        {isRevealPassword ?
                                                                            <FontAwesomeIcon icon={faEye} className="customIcon"/> :
                                                                            <FontAwesomeIcon icon={faEyeSlash} className="customIcon"/>
                                                                        }
                                                                    </span>
                                                                </span>
                                                                </div>


                                                            </div>


                                                            <div className="form-button" style={{ textAlign: 'center'}}>
                                                                {this.state.disabledButton === false ? (
                                                                    <a className="btn btn-primary" type="submit" style={{backgroundColor: 'gray !important', color: "white", textAlign: 'center', width: '100%'}}
                                                                       onClick={() => {
                                                                           this.submitLogin()
                                                                       }}>Masuk Akun</a>
                                                                ) : (
                                                                    <a className="btn btn-primary" type="submit" style={{color: "white"}} disabled><i className="fa fa-refresh fa-spin"></i> Loading...</a>
                                                                )}
                                                            </div>
                                                        </form>
                                                        :
                                                        <form className="form-horizontal auth-form">
                                                            <label>OTP telah dikirim ke Email anda, silahkan cek email anda dan masukkan OTP tersebut ke form dibawah..</label>
                                                            <div className="form-group">
                                                                <input required=""
                                                                       name="user_otp"
                                                                       value={this.state.user_otp}
                                                                       onChange={this.handleChange}
                                                                       type="number"
                                                                       className="form-control"
                                                                       placeholder="OTP Pengguna..."/>
                                                            </div>
                                                            <div className="form-button">
                                                                {this.state.disabledButton === false ? (
                                                                    <a className="btn btn-primary" type="submit" style={{color: "white"}}
                                                                       onClick={() => {
                                                                           this.submitAuth()
                                                                       }}>Login</a>
                                                                ) : (
                                                                    <a className="btn btn-primary" type="submit" style={{color: "white"}} disabled><i className="fa fa-refresh fa-spin"></i> Loading...</a>
                                                                )}
                                                            </div>
                                                        </form>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
