import React, { Component } from 'react'
class statusBlog extends Component {
    render () {
        switch (this.props.status) {
            case 'active':
                return <span className="badge badge-success">Active</span>;
            case 'draft':
                return <span className="badge badge-warning">Draft</span>;
            default :
                return <span>-</span>
        }
    }
}

statusBlog.defaultProps = {
    status: ''
}

export default statusBlog;
