import React, {Component, Fragment, useState} from 'react'

import Breadcrumb from '../common/breadcrumb';
import {API_HOST, IMG_HOST} from "../../helper/host";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Settings, User} from "react-feather";
import {set_auth, clear_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import Swal from 'sweetalert2'
import CKEditor from "react-ckeditor-component";

export class SettingTentangKami extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            imageUrl: "",
            fileList: [],
            app_name: "",
            about: "",
            privacy: "",
            instagram_link: "",
            facebook_link: "",
            youtube_link: "",
            phone: "",
            whatsapp: "",
            address: "",
            email: "",
            url_android: "",
            url_ios: "",
            prosesSimpan: false,
            edit_profile: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSimpan = this.handleSimpan.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            about: newContent
        })
    }

    handleSimpan() {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            app_name: this.state.app_name,
            about: this.state.about,
            privacy: this.state.privacy,
            instagram_link: this.state.instagram_link,
            facebook_link: this.state.facebook_link,
            youtube_link: this.state.youtube_link,
            phone: this.state.phone,
            whatsapp: this.state.whatsapp,
            address: this.state.address,
            email: this.state.email,
            url_android: this.state.url_android,
            url_ios: this.state.url_ios,
        }
        this.props.request("admin/setting/general_save", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Pemberitahuan!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800
                    })
                    this.loadData()
                }
                this.setState({
                    prosesSimpan: false,
                })
            });
    }

    loadData() {
        let form = {}
        this.props.request("admin/setting/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        app_name: response.data.app_name,
                        about: response.data.app_about,
                        privacy: response.data.app_privacy,
                        instagram_link: response.data.contact_instagram_link,
                        facebook_link: response.data.contact_facebook_link,
                        youtube_link: response.data.contact_youtube_link,
                        phone: response.data.contact_phone,
                        whatsapp: response.data.contact_whatsapp,
                        address: response.data.contact_address,
                        email: response.data.contact_email,
                        url_android: response.data.app_url_android,
                        url_ios: response.data.app_url_ios,
                        fileList: [
                            {
                                uid: "-1",
                                name: response.data.app_logo,
                                status: "done",
                                url: IMG_HOST + response.data.app_logo
                            }
                        ],
                    });
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Pengaturan Tentang Kami" parent="Pengaturan"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <div>
                                        <form action="">
                                            <div className="tab-pane fade show active">
                                                <h5 className="f-w-600 f-16">Tentang Kami</h5>
                                                <div className="form-group row">
                                                    <CKEditor
                                                        content={this.state.about}
                                                        config={{
                                                            cloudServices_uploadUrl: 'https://your-organization-id.cke-cs.com/easyimage/upload/'
                                                        }}
                                                        events={{
                                                            change:this.onEditorStateChange
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                        <div className="pull-right">
                                            {this.state.prosesSimpan === false ?
                                                <button type="button" className="btn btn-primary"
                                                        onClick={this.handleSimpan}>Simpan</button>
                                                :
                                                <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    };
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingTentangKami);