import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import './index.scss';
import Main from "./components/main";
import {Provider} from "react-redux";
import configureStore from "./config/configureStore";
const store = configureStore();
class Root extends Component {
    render() {
        return (
            <Provider store={store}>
                <Main />
            </Provider>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


