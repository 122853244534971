import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import {Upload} from "antd";
import {AvForm, AvGroup,} from "availity-reactstrap-validation";
import ImgCrop from "antd-img-crop";
import 'antd/dist/antd.css';
import customToast from "../../notification/customToast";
import Select from 'react-select';
import {Input} from "reactstrap";

export class Add_company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCompany         : [],
            fileList            : [],
            Paket               : [],
            paging              : 15,
            perPage             : 0,
            pageCount           : 0,
            keyword             : '',
            idDel               : '',
            status              : 'pending',
            currentPage         : 0,
            isLoading           : true,
            loading             : false,
            auth                : this.props.auth,
            disabledButton      : false,
            perusahaan_nama     : "",
            perusahaan_email    : '',
            perusahaan_password : '',
            perusahaan_user     : '',
            perusahaan_alamat   : '',
            perusahaan_telp     : '',
            perusahaan_bidang   : '',
            created_at          : '',
            confirm_password    : '',
            paket_nama          : '',
            paket_id            : '',
            metode_pembayaran   : '',
            pilihPaket          : null

        }
        this.handleChange       = this.handleChange.bind(this)
        this.handleSubmit       = this.handleSubmit.bind(this)
        this.handleSelectPaket  = this.handleSelectPaket.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSelectPaket = (pilihPaket) => {
        this.setState({
            pilihPaket,
            paket_id        : pilihPaket.value,
            paket_nama      : pilihPaket.label,

        });

    };

    handleSubmit = () => {
        this.setState({
            loading         : true,
        })
        let form = {
            perusahaan_id       : this.state.perusahaan_id,
            perusahaan_nama     : this.state.perusahaan_nama,
            paket_nama          : this.state.paket_nama,
            paket_id            : this.state.paket_id,
            perusahaan_email    : this.state.perusahaan_email,
            perusahaan_password : this.state.perusahaan_password,
            perusahaan_user     : this.state.perusahaan_user,
            perusahaan_alamat   : this.state.perusahaan_alamat,
            perusahaan_telp     : this.state.perusahaan_telp,
            status              : 'pending',
            perusahaan_bidang   : this.state.perusahaan_bidang,
            perusahaan_foto     : this.state.perusahaan_foto,
            confirm_password    : this.state.confirm_password,
            metode_pembayaran   : this.state.metode_pembayaran,
        }

        this.props.request("admin/perusahaan/add", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading       : true,
                        perusahaan_foto : {},
                        fileList        : [],
                        paket_nama      : '',
                        pilihPaket      : null

                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                    this.props.history.push('/company/list-company')
                }else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }

    loadData() {
        let formData = {
            id      : this.state.id,
            auth    : this.props.auth
        }
        this.props.request("admin/perusahaan/list", formData)
            .then(response => {
                if (response.success) {
                    var result = response.data
                    var paket  = response.paket
                    this.setState({
                        dataCompany : result.data,
                        Paket       : paket,
                        modal       : false,
                        auth        : this.props.auth,
                        isLoading   : false,
                        loading     : false,
                    });

                }
            });
    }

    componentDidMount() {
        this.loadData();
    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({auth: newProps.auth})
        }
    }



    render() {

        const { loading }= this.state
        const defaultValues = {
            status: 'pending',
        };
        return (
            <Fragment>
                <Breadcrumb title="Tambah Perusahaan" parentLink="/company/list-company" parent="Perusahaan"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <AvForm className="needs-validation user-add" noValidate="" model={defaultValues}>
                                        <h4>Tambah Perusahaan</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Foto Perusahaan</label>
                                            <div className="col xl-8 col-md-7">
                                                <ImgCrop
                                                    useRatio={true}
                                                    width={100}
                                                    height={100}>
                                                    <Upload action="http://localhost:8000/"
                                                            listType="picture-card"
                                                            style={{width: '200px !important'}}
                                                            fileList={this.state.fileList}
                                                            onChange={(file) => {
                                                                this.setState({ fileList: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            perusahaan_foto: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ perusahaan_foto: [] })
                                                                }
                                                            }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null : "+ Add image"}
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Nama Perusahaan</label>
                                            <input name="perusahaan_nama" className="form-control col-xl-8 col-md-7" placeholder="Nama Perusahaan..."
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Alamat Perusahaan</label>
                                            <input name="perusahaan_alamat" className="form-control col-xl-8 col-md-7" placeholder="Alamat Perusahaan"
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Nomor Tlp. Perusahaan</label>
                                            <input name="perusahaan_telp" className="form-control col-xl-8 col-md-7" placeholder="Nomor Tlp. Perusahaan"
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Bidang Perusahaan</label>
                                            <input name="perusahaan_bidang" className="form-control col-xl-8 col-md-7" placeholder="Bidang Perusahaan"
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Pilih Paket</label>
                                            <div className="col-md-8" style={{padding:0}}>
                                                <Select
                                                    value={this.state.pilihPaket}
                                                    placeholder="Pilih Paket..."
                                                    onChange={this.handleSelectPaket}
                                                    options={
                                                        this.state.Paket.map((item, index) => (
                                                                {
                                                                    key     : index,
                                                                    value   : item.paket_id,
                                                                    label   : item.paket_nama + ' - ' + ' (' + item.paket_jumlah +' orang) ' + '-' + ' ('+ item.paket_bulan+' bulan) ',
                                                                }
                                                            )
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Metode Pembayaran</label>
                                                <Input bsSize="sm" className="form-control col-xl-8 col-md-7" type="select" name="metode_pembayaran" onChange={this.handleChange} value={this.state.metode_pembayaran}>
                                                    <option value="#">Pilih Metode Pembayaran</option>
                                                    <option value="tf">Transfer</option>
                                                    <option value="va">Virtual Account</option>
                                                    <option value="cc">Credit Card</option>
                                                    <option value="emoney">E-Money</option>
                                                </Input>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> User Perusahaan</label>
                                            <input name="perusahaan_user" className="form-control col-xl-8 col-md-7" placeholder="User Perusahaan"
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Email Perusahaan</label>
                                            <input name="perusahaan_email" className="form-control col-xl-8 col-md-7" placeholder="Email Perusahaan"
                                                   type="email" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Password Perusahaan</label>
                                            <input name="perusahaan_password" className="form-control col-xl-8 col-md-7" placeholder="Password Perusahaan"
                                                   type="password" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Ulangi Password</label>
                                            <input name="confirm_password" className="form-control col-xl-8 col-md-7" placeholder="Ulangi Password Perusahaan"
                                                   type="password" required="" onChange={this.handleChange}/>
                                        </div>
                                    </AvForm>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Simpan</span>}
                                            {!loading && <span>Simpan</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Add_company
