import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';
import { withRouter, Redirect } from 'react-router-dom';
import {requestData} from "../../helper/request";
import Swal from 'sweetalert2'
import { set_auth } from "../../reducers/Auth"
import { connect } from 'react-redux'

export class RegisterTabset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: this.props.auth,
            disabledButton: false,
            accountForm: false,
            activeShow: true,
            confirm: false,
            data_register: false,
            emaillogin: "",
            passwordlogin: "",
            otplogin: "",
            emailregister: "",
            passwordregister: "",
            passwordconfirmregister: "",
            startDate: new Date()
        }
        this.handleChange = this.handleChange.bind(this)
    }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    submitLogin = () => {
        this.setState({
            disabledButton: true
        })

        let form = {
            perusahaan_email: this.state.emaillogin,
            perusahaan_password: this.state.passwordlogin,
        }

        requestData("req_code", form)
            .then(response => {
                if (response.success) {
                    this.setState({
                        confirm: true
                    })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });

        this.setState({
            disabledButton: false
        })    }

    submitAuth = () => {
        this.setState({
            disabledButton: true
        })

        let form = {
            perusahaan_email: this.state.emaillogin,
            perusahaan_password: this.state.passwordlogin,
            perusahaan_otp: this.state.otplogin,
        }

        requestData("login", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        showConfirmButton: false,
                        icon: 'success',
                    })
                    this.props.set_auth(response.api_token, response.data);
                    window.location.reload(false);
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });

        this.setState({
            disabledButton: false
        })
    }

    submitRegister = () => {
        this.setState({
            disabledButton: true
        })

        let form = {
            perusahaan_email: this.state.emailregister,
            perusahaan_password: this.state.passwordregister,
            perusahaan_confirm_password: this.state.passwordconfirmregister,
        }

        requestData("register", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.success,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload(false);
                            }
                        })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
            });

        this.setState({
            disabledButton: false
        })
    }

    componentDidMount() {
        this.setState({ auth: this.props.auth })
    }

    componentWillReceiveProps(newProps) {
        if(newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }

    render() {
        return (
            <div>
                <Fragment>
                    <div className="card-header">
                        <h5>Daftar</h5>
                    </div>
                    {!this.state.accountForm ?
                        <form className="form-horizontal auth-form">
                            <div className="form-group">
                                <input required="" name="namaregister" onChange={this.handleChange} type="text"
                                       className="form-control" placeholder="Nama Perusahaan"/>
                            </div>
                            <div className="form-group">
                                <input required="" name="bidangregister" onChange={this.handleChange} type="text"
                                       className="form-control" placeholder="Bidang Perusahaan"/>
                            </div>
                            <div className="form-group">
                                       <textarea name="alamatregister" onChange={this.handleChange} className="form-control" placeholder="Alamat Perusahaan"></textarea>
                            </div>
                            <div className="form-group">
                                <input required="" name="nohpregister" onChange={this.handleChange} type="number"
                                       className="form-control" placeholder="No. HP Perusahaan"/>
                            </div>
                            <div className="form-button">
                                {this.state.disabledButton === false ? (
                                    <a className="btn btn-primary" type="submit" style={{color: "white"}}
                                       onClick={this.nextForm}>Lanjutkan</a>
                                ) : (
                                    <a className="btn btn-primary" type="submit" style={{color: "white"}}
                                       onClick={this.submitRegister} disabled>Lanjutkan</a>
                                )}
                            </div>
                            <div className="form-footer">
                                <span><a href="/login">Sudah Punya Akun? Login Disini</a></span>
                            </div>
                        </form>
                        :
                        <form className="form-horizontal auth-form">
                            <div className="form-group">
                                <input required="" name="emailregister" onChange={this.handleChange} type="email"
                                       className="form-control" placeholder="Email"/>
                            </div>
                            <div className="form-group">
                                <input required="" name="passwordregister" onChange={this.handleChange} type="password"
                                       className="form-control" placeholder="Password"/>
                            </div>
                            <div className="form-group">
                                <input required="" name="passwordconfirmregister" onChange={this.handleChange}
                                       type="password" className="form-control" placeholder="Ulangi Password"/>
                            </div>
                            <div className="form-terms">
                                <div className="custom-control custom-checkbox mr-sm-2">
                                    <input type="checkbox" className="custom-control-input"
                                           id="customControlAutosizing"/>
                                    <label className="d-block">
                                        <input className="checkbox_animated" id="chk-ani2" checked readOnly
                                               type="checkbox"/>
                                        Saya setuju dengan <span><a href="">Syarat & Kondisi</a></span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-button">
                                {this.state.disabledButton === false ? (
                                    <a className="btn btn-primary" type="submit" style={{color: "white"}}
                                       onClick={this.submitRegister}>Lanjutkan</a>
                                ) : (
                                    <a className="btn btn-primary" type="submit" style={{color: "white"}}
                                       onClick={this.submitRegister} disabled>Lanjutkan</a>
                                )}
                            </div>
                            <div className="form-footer">
                                <span><a href="/login">Sudah Punya Akun? Login Disini</a></span>
                            </div>
                        </form>
                    }
                </Fragment>



            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterTabset)

