import React, {Component, Fragment, useState} from 'react'

import Breadcrumb from '../common/breadcrumb';
import {API_HOST, IMG_HOST} from "../../helper/host";
import {set_auth, clear_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import Swal from 'sweetalert2'
import LoadingScreen from '../loadingScreen/loading';
import {Upload} from "antd";

export class SettingGeneral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data            : [],
            imageUrl        : "",
            fileList        : [],
            app_name        : "",
            about           : "",
            privacy         : "",
            instagram_link  : "",
            facebook_link   : "",
            youtube_link    : "",
            phone           : "",
            whatsapp        : "",
            address         : "",
            email           : "",
            url_android     : "",
            url_ios         : "",
            cs_wa           : "",
            group_telegram  : "",
            vendor_email  : "",
            cmsbanner  : "",
            prosesSimpan    : false,
            edit_profile    : false,
            firstLoading    : true,

        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSimpan = this.handleSimpan.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSimpan() {
        this.setState({
            prosesSimpan: true,
        })

        let form = {
            app_name: this.state.app_name,
            about: this.state.about,
            privacy: this.state.privacy,
            instagram_link: this.state.instagram_link,
            facebook_link: this.state.facebook_link,
            youtube_link: this.state.youtube_link,
            phone: this.state.phone,
            whatsapp: this.state.whatsapp,
            address: this.state.address,
            email: this.state.email,
            url_android: this.state.url_android,
            url_ios: this.state.url_ios,
            cs_wa: this.state.cs_wa,
            group_telegram: this.state.group_telegram,
            vendor_email: this.state.vendor_email,
            cmsbanner: this.state.cmsbanner,
        }
        this.props.request("admin/setting/general_save", form, 'POST')
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Pemberitahuan!',
                        text: response.message,
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 800
                    })
                    this.loadData()
                }
                this.setState({
                    prosesSimpan: false,
                    paket_foto      : '',
                    fileList        : [],
                })
            });
    }

    demoAsyncCall() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2500));
    }

    loadData() {
        let form = {}
        this.props.request("admin/setting/list", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data: response.data,
                        app_name: response.data.app_name,
                        about: response.data.app_about,
                        privacy: response.data.app_privacy,
                        instagram_link: response.data.contact_instagram_link,
                        facebook_link: response.data.contact_facebook_link,
                        youtube_link: response.data.contact_youtube_link,
                        phone: response.data.contact_phone,
                        whatsapp: response.data.contact_whatsapp,
                        address: response.data.contact_address,
                        email: response.data.contact_email,
                        url_android: response.data.app_url_android,
                        url_ios: response.data.app_url_ios,
                        cs_wa: response.data.cs_wa,
                        group_telegram: response.data.group_telegram,
                        vendor_email: response.data.vendor_email,
                        cmsbanner: response.data.cmsbanner ? response.data.cmsbanner : "",
                        fileList: response.data.cmsbanner ? [
                            {
                                uid: "-1",
                                name: response.data.cmsbanner,
                                status: "done",
                                url: IMG_HOST + "banner/" + response.data.cmsbanner
                            }
                        ] : [],
                    });
                }
            });
    }

    componentDidMount() {
        this.loadData();
        this.demoAsyncCall().then(() => this.setState({ firstLoading: false }));
    }

    render() {

        const { firstLoading } = this.state;
        if(firstLoading) {
            return <LoadingScreen/>;
        }

        return (
            <Fragment>
                <Breadcrumb title="Pengaturan General" parent="Pengaturan"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <div>
                                        <form action="">
                                            <div className="tab-pane fade show active">
                                                <h5 className="f-w-600 f-16">Data General</h5>
                                                <div className="table-responsive profile-table">
                                                    <table className="table table-responsive">
                                                        <tbody>
                                                        <tr style={{width: '100%'}}>
                                                            <td>Nama Aplikasi</td>
                                                            <td style={{width: '100%'}}><input type="text"
                                                                       className="form-control"
                                                                       name="app_name"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.app_name}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>URL Instagram</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="instagram_link"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.contact_instagram_link}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>URL Facebook</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="facebook_link"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.contact_facebook_link}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>URL Youtube</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="youtube_link"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.contact_youtube_link}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>No. Handphone</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="phone"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.contact_phone}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>No. Whatsapp</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="whatsapp"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.contact_whatsapp}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Alamat</td>
                                                            <td><textarea className="form-control"
                                                                          name="address"
                                                                          value={this.state.address}
                                                                          onChange={this.handleChange}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="email"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.contact_email}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>URL Android</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="url_android"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.app_url_android}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>URL iOS</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="url_ios"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.app_url_ios}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>CS Whatshapp</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="cs_wa"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.cs_wa}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Group Telegram</td>
                                                            <td><input type="text"
                                                                       className="form-control"
                                                                       name="group_telegram"
                                                                       onChange={this.handleChange}
                                                                       defaultValue={this.state.data.group_telegram}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Vendor Email</td>
                                                            <td>
                                                                <select className="form-control w-100" name="vendor_email" onChange={this.handleChange} value={this.state.vendor_email}>
                                                                    <option value="">Pilih Vendor Email</option>
                                                                    <option value="mailjet">Mailjet (200 email per hari)</option>
                                                                    <option value="sendgrid">SendGrid</option>
                                                                    <option value="sendinblue">Sendinblue (300 email per hari)</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gambar Banner</td>
                                                            <td><Upload action="http://localhost:8000/"
                                                                        listType="picture-card"
                                                                        name="cmsbanner"
                                                                        style={{width: '200px !important'}}
                                                                        fileList={this.state.fileList}
                                                                        onChange={(file) => {
                                                                            this.setState({ fileList: file.fileList })

                                                                            if (file.fileList.length > 0) {
                                                                                let files = file.file.originFileObj
                                                                                let reader = new FileReader()

                                                                                reader.readAsDataURL(files)
                                                                                reader.onloadend = () => {
                                                                                    this.setState({
                                                                                        cmsbanner: {
                                                                                            name: file.file.name,
                                                                                            file: reader.result
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }
                                                                            else {
                                                                                this.setState({ cmsbanner: [] })
                                                                            }
                                                                        }}
                                                            >
                                                                {this.state.cmsbanner && this.state.cmsbanner.length >= 1 ? null : "+ Add image"}
                                                            </Upload>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="pull-right">
                                            {this.state.prosesSimpan === false ?
                                                <button type="button" className="btn btn-primary"
                                                        onClick={this.handleSimpan}>Simpan</button>
                                                :
                                                <button type="button" className="btn btn-primary"><i className="fa fa-refresh fa-spin"></i> Loading</button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    };
}

const mapStateToProps = state => ({
    auth: state.Auth.auth,
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data)),
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingGeneral);
