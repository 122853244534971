import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import {Upload} from "antd";
import {AvForm, AvGroup,} from "availity-reactstrap-validation";
import ImgCrop from "antd-img-crop";
import 'antd/dist/antd.css';
import customToast from "../../notification/customToast";
import Select from 'react-select';
import {Input} from "reactstrap";

export class BroadcastAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCompany         : [],
            fileList            : [],
            Paket               : [],
            paging              : 15,
            perPage             : 0,
            pageCount           : 0,
            keyword             : '',
            idDel               : '',
            status              : 'pending',
            currentPage         : 0,
            isLoading           : true,
            loading             : false,
            auth                : this.props.auth,
            disabledButton      : false,
            notif_title     : "",
            notif_message    : '',
            pilihPaket          : null

        }
        this.handleChange       = this.handleChange.bind(this)
        this.handleSubmit       = this.handleSubmit.bind(this)
        this.handleSelectPaket  = this.handleSelectPaket.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSelectPaket = (pilihPaket) => {
        this.setState({
            pilihPaket,
            paket_id        : pilihPaket.value,
            paket_nama      : pilihPaket.label,

        });

    };

    handleSubmit = () => {
        this.setState({
            loading         : true,
        })
        let form = {
            title       : this.state.notif_title,
            message     : this.state.notif_message,
        }

        this.props.request("admin/perusahaan/broadcast/add", form)
            .then(response => {
                if (response.success) {
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/company/broadcast-company')
                }else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({auth: newProps.auth})
        }
    }



    render() {

        const { loading }= this.state
        const defaultValues = {
            status: 'pending',
        };
        return (
            <Fragment>
                <Breadcrumb title="Buat Broadcast" parentLink="/company/list-company" parent="Perusahaan"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <AvForm className="needs-validation user-add" noValidate="" model={defaultValues}>
                                        <h4>Tambah Broadcast</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Judul Broadcast</label>
                                            <input name="notif_title" className="form-control col-xl-8 col-md-7" placeholder="Nama Perusahaan..."
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Pesan Broadcast</label>
                                            <input name="notif_message" className="form-control col-xl-8 col-md-7" placeholder="Alamat Perusahaan"
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                    </AvForm>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Simpan</span>}
                                            {!loading && <span>Simpan</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default BroadcastAdd
