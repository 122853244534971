import React, { Component } from 'react';

class CurrencyInput extends Component {
    handleChange = (e) => {
        let value = e.target.value;

        value = value.replace(/\D/g, '');

        value = new Intl.NumberFormat('en-US').format(value);

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { value, placeholder } = this.props;

        return (
            <input
                className="form-control col-xl-8 col-md-7"
                placeholder={placeholder || ""}
                type="text"
                required
                value={value}
                onChange={this.handleChange}
            />
        );
    }
}

export default CurrencyInput;
