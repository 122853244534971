import React, { Component } from 'react'
class statusVerify extends Component {
    render () {
        switch (this.props.status) {
            case 'success':
                return <span className="badge badge-success">Success</span>;
            case 'reject':
                return <span style={{backgroundColor:'#d92550'}} className="badge badge-danger">Reject</span>;
            case 'cancel':
                return <span style={{backgroundColor:'#d92550'}} className="badge badge-danger">Cancel</span>;
            case 'pending':
                return <span className="badge badge-warning">Pending</span>;
            case 'expired':
                return <span className="badge badge-dark">Expired</span>;
            case 'refund':
                return <span className="badge badge-info">Refund</span>;
            default :
                return <span>-</span>
        }
    }
}

statusVerify.defaultProps = {
    status: ''
}

export default statusVerify;
