import React, {Component, Fragment} from 'react';
import {
    Button,
    ButtonGroup,
    Table,
    FormGroup,
    Input
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/id'
import {pageNumber} from "../../functions/functionGeneral";
import "react-image-lightbox/style.css";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import "../form/components/DatePicker/Examples/style.css"
import "react-datepicker/dist/react-datepicker.css";
import arrowUp from "../../assets/icon/arrow-up.svg";
import arrowDown from "../../assets/icon/arrow-down.svg";
import "./style.css";
import ReactPaginate from "react-paginate";
import arrowRight from "../../assets/icon/arrow-right.svg"
import arrowLeft from "../../assets/icon/arrow-left.svg"

export class List_kritik extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data                    : [],
            perPage                 : 0,
            currentPage             : 0,
            keyword                 : "",
            paging                  : 50,
            perusahaan_id           : '',
            totalData               : 0,
            isLoading               : true,
        }
        this.handlePaging               = this.handlePaging.bind(this);
        this.handlePageClick            = this.handlePageClick.bind(this);
        this.handleChange               = this.handleChange.bind(this);
        this.handleSearchKeyword        = this.handleSearchKeyword.bind(this);
    }

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            data: [],
            isLoading: true,
            [name]: value
        },()=>{
            this.loadData()
        })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            [name]: value
        });
    }

    handleSearchKeyword(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            keyword: value
        })
    }

    handlePageClick(data) {

        let selected = data.selected; // zero based index

        this.setState({ currentPage: selected }, () => {
            this.loadData();

        });
    };

    sortabledate = (val) => {

        const copyArray = this.state.data

        copyArray.sort((a, b) => {
            return val === 0 ? new Date(a.survey_created_date) - new Date(b.survey_created_date) : new Date(b.survey_created_date) - new Date(a.survey_created_date);
        });

        this.setState({
            data: copyArray
        })
    }

    loadData() {
        /*if (this.state.intervalId !== null)
            clearInterval(this.state.intervalId);*/

        let formData = {
            perusahaan_id           : this.state.perusahaan_id,
            page                    : this.state.currentPage + 1,
            paging                  : this.state.paging,
            keyword                 : this.state.keyword
        };

        this.props.request('admin/perusahaan/kritik-saran-perusahaan', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    var result = response.data;

                    this.setState({
                            data            : result.data,
                            totalData       : response.data.total,
                            pageCount       : result.last_page,
                            perPage         : result.per_page,
                        },
                        ()=>{
                            /*const intervalId = setInterval(() => {
                                this.realtimeData()
                            }, 3000);*/

                            // this.setState({ intervalId: intervalId })
                        })
                }
                this.setState({ isLoading: false })
            })

    }

    refresh() {
        this.setState({
            data                : [],
            keyword             : "",
            isLoading           : true,
        }, () => {
            this.loadData()
        })
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        var no = 0;

        if(['Admin'].indexOf(this.props.user.level) >= 0) {
            return (
                <Fragment>
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <h5 style={{ color: '#143B5E', fontSize: '24px'}}>Kritik dan Saran Perusahaan</h5>
                                <p> perusahaan</p>
                            </div>
                            <div className="row" style={{marginLeft: '5px'}}>
                                <div className="col-3 mt-3">
                                    <FormGroup style={{marginLeft:11}}>
                                        <Input type="text"
                                               className={this.state.keyword === "" ? "mb-2 form-control-sm form-control bg-image-input-search" : "mb-2 form-control-sm form-control no-bg-image-input-search"}
                                               name="keyword"
                                               style={{ backgroundColor: "#F9F9F9", fontSize: "12px", fontWeight: 400, paddingTop: "9px", paddingBottom: "9px" }}
                                               onChange={this.handleSearchKeyword}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()

                                                   if (event.which == 13) {
                                                       this.setState({
                                                           isLoading    : true
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}
                                               value={this.state.keyword}
                                               placeholder="        Cari Nama, nomor hp, atau  email ..."/>
                                    </FormGroup>
                                </div>
                                <div className="col-2 mt-3 ml-3">
                                    <div className="btn-actions-pane-right">
                                        <ButtonGroup size="sm" style={{width:"44px", height:"44px"}}>
                                            <Button caret="true" color={"#F9F9F9"}
                                                    className={"btn-shadow "}
                                                    onClick={() => {
                                                        this.refresh();
                                                    }}
                                            ><i className="pe-7s-refresh"><img src={require('../../assets/icon/refresh.svg')} /></i></Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body d-flex">
                                <div className="clearfix"></div>
                                <Table className="mb-5">
                                    <thead className='text-table'>
                                        <tr>
                                            <th width={30}>#</th>
                                            <th width={300}>Nama</th>
                                            <th width={150}>
                                                <div className='d-flex'>
                                                    <div>
                                                        Tanggal Dibuat
                                                    </div>
                                                    <div className='ml-1 d-flex flex-column pt-1'>
                                                        <img alt='' onClick={() => {
                                                            this.sortabledate(1)
                                                        }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={arrowUp} />
                                                        <img alt='' onClick={() => {
                                                            this.sortabledate(0)
                                                        }} style={{ width: "8px", height: "8px" }} className='izin-btn' src={arrowDown} />
                                                    </div>
                                                </div>
                                            </th>
                                            <th>Kritik dan Saran</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {!this.state.isLoading && this.state.data.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.data.map((item, index) => {
                                        no += 1
                                        return (
                                            <tr key={index} className="text-column" style={{backgroundColor: (item.langganan != null ? item.langganan.langganan_status : '') ==  'expired' && '#fa8878'}}>
                                                <small>
                                                    <th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</th>
                                                </small>
                                                <td>
                                                    {item.perusahaan.perusahaan_nama}<br/>{item.perusahaan.perusahaan_bidang}
                                                    <small>
                                                        <br/>{item.perusahaan.perusahaan_email}
                                                    </small>
                                                </td>
                                                <td>
                                                    <small>
                                                        {moment(item.survey_created_date).format('lll')} WIB
                                                    </small>
                                                </td>
                                                <td className="wrapping-text">
                                                    {item.survey_content != null ? item.survey_content : "-"}
                                                </td>
                                            </tr>

                                        )})}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='card-footer p-0 border-0'>
                                <div className='row mx-0' style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                                    {this.state.data.length !== 0 &&
                                        <>
                                            <div className='col font-14' style={{ padding: "auto", margin: "auto", fontWeight: 500, lineHeight: "24px" }}>menampilkan {this.state.data.length === 0 || this.state.data.length === null ? "0" : this.state.data.length} dari {this.state.totalData} data</div>
                                            <div className='col paginate-item'>
                                                <ReactPaginate
                                                    previousLabel={<img src={arrowLeft} alt='' />}
                                                    nextLabel={<img src={arrowRight} alt='' />}
                                                    pageCount={this.state.pageCount}
                                                    forcePage={this.state.currentPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={3}
                                                    onPageChange={this.handlePageClick}
                                                    breakClassName={'page-item'}
                                                    breakLinkClassName={'page-link kritik-page-link'}
                                                    containerClassName={'pagination my-2'}
                                                    pageClassName={'page-item'}
                                                    pageLinkClassName={'page-link kritik-page-link'}
                                                    previousClassName={'page-item'}
                                                    previousLinkClassName={'page-link kritik-prev-next-link'}
                                                    nextClassName={'page-item'}
                                                    nextLinkClassName={'page-link kritik-prev-next-link'}
                                                    activeClassName={'active kritik-active'}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }else{
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_kritik);
