import React, { Fragment,Component } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
// import '../../index.scss';
import App from '../../components/app';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ScrollContext } from 'react-router-scroll-4';
import { connect } from 'react-redux'
import {set_auth, init_auth, clear_auth} from "../../reducers/Auth";
import {requestData, requestAuth} from "../../helper/request";
import AdminRoute from "../../authRoute";
//
// import configureStore from '../../config/configureStore';


// Components
import Dashboard from '../../components/Dashboards/dashboard';
import List_paket from '../../components/Paket/List';
import Add_paket from '../../components/Paket/Add';
import Edit_paket from '../../components/Paket/edit';
import List_User from '../../components/Users/List';
import Edit_User from '../../components/Users/edit';
import Add_User from "../Users/Add_user";
import List_Company from '../../components/Perusahaan/List';
import Add_company from "../Perusahaan/Add_company";
import Detail_company from "../Perusahaan/detail";
import Edit_company from "../Perusahaan/edit";
import Login from '../../components/auth/login';
import Register from '../../components/auth/register';
import SettingGeneral from "../Setting/setting-general";
import {SettingTentangKami} from "../Setting/setting-tentang-kami";
import SettingPrivasi from "../Setting/setting-privasi";
import List_Blog from "../../components/Blog/List";
import Add_Blog from "../../components/Blog/Add";
import Edit_Blog from "../../components/Blog/edit";

import { Provider } from 'react-redux';
import configureStore from '../../config/configureStore';
import PerusahaanBookmarkLists from "../Perusahaan/bookmark_list";
import BroadcastLists from "../Perusahaan/broadcast_list";
import BroadcastAdd from "../Perusahaan/add_broadcast";
import List_kritik_saran from "../Perusahaan/List_kritik_saran";
import SetBanner from "../Banner/edit";

const store = configureStore();

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            data : []
        };
    }
    componentDidMount() {
        var Auth    = localStorage.getItem('Presensi-Auth')
        var AuthKey = localStorage.getItem('Presensi-Auth-Key')
        var AuthUser = localStorage.getItem('Presensi-Auth-User')

        if(Auth != null && AuthKey != null && AuthUser != null)
            this.props.init_auth(Auth, AuthKey, JSON.parse(AuthUser))
    }

    request = async (action, data, method) => {
        var AuthKey = await localStorage.getItem('Presensi-Auth-Key')

        await requestAuth('admin/auth', AuthKey)
            .then((response) => {
                if(!response.auth) {
                    this.props.clear_auth()
                }
            })

        if(action && data) {
            var AuthKey = await localStorage.getItem('Presensi-Auth-Key')

            data = { token: AuthKey, ...data }

            return requestData(action, data, method)
        }
    }

    render() {
        return (
            <Fragment>
                <BrowserRouter basename={'/'}>
                    <ScrollContext>
                        <Switch>
                            <Route exact path="/"
                                   render={(props) => {
                                       return <Login
                                           request={(action, data, method = 'GET') => requestData(action, data, method)}
                                           {...props}/>
                                   }}/>
                            <Route exact path="/login"
                                   render={(props) => {
                                       return <Login
                                           request={(action, data, method = 'GET') => requestData(action, data, method)}
                                           {...props}/>
                                   }}/>
                            <Route exact path="/register"
                                   render={(props) => {
                                       return <Register
                                           request={(action, data, method = 'GET') => requestData(action, data, method)}
                                           {...props}/>
                                   }}/>
                            {/*<Route exact path={`${process.env.PUBLIC_URL}/auth/login`} component={Login} />*/}
                            <App request={(action, data, method = 'GET') => this.request(action, data, method)}>
                                <AdminRoute
                                    path="/dashboard"
                                    component={Dashboard}
                                    request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                                <AdminRoute path={`${process.env.PUBLIC_URL}/banner`} component={SetBanner}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                                <AdminRoute path={`${process.env.PUBLIC_URL}/paket/list-paket`} component={List_paket}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                                <AdminRoute path={`${process.env.PUBLIC_URL}/paket/add-paket`} component={Add_paket}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                                <AdminRoute path={`${process.env.PUBLIC_URL}/paket/edit-paket/:id`} component={Edit_paket}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>


                                <AdminRoute path={`${process.env.PUBLIC_URL}/blog/list-blog`} component={List_Blog}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                                <AdminRoute path={`${process.env.PUBLIC_URL}/blog/add-blog`} component={Add_Blog}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                                <AdminRoute path={`${process.env.PUBLIC_URL}/blog/edit-blog/:id`} component={Edit_Blog}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>


                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/list-company`} component={List_Company}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/list-kritik-saran`} component={List_kritik_saran}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/bookmark-company`} component={PerusahaanBookmarkLists}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/broadcast-company`} component={BroadcastLists}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/broadcast/add`} component={BroadcastAdd}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/add-company`} component={Add_company}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/detail-company/:id`} component={Detail_company}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/company/edit-company/:id`} component={Edit_company}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/users/list-users`} component={List_User}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path={`${process.env.PUBLIC_URL}/users/add-users`} component={Add_User}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute path="/users/edit-users/:id" component={Edit_User}
                                            request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute
                                    path="/pengaturan/general"
                                    component={SettingGeneral}
                                    request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute
                                    path="/pengaturan/privasi"
                                    component={SettingPrivasi}
                                    request={(action, data, method = 'GET') => this.request(action, data, method)}/>
                                <AdminRoute
                                    path="/pengaturan/tentang-kami"
                                    component={SettingTentangKami}
                                    request={(action, data, method = 'GET') => this.request(action, data, method)}/>

                                <ToastContainer
                                    autoClose={2000}
                                    position="top-right"
                                    className="toast-container"
                                    toastClassName="dark-toast"
                                />
                            </App>

                        </Switch>
                    </ScrollContext>

                </BrowserRouter>

            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    api_token: state.Auth.api_token,
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    init_auth: (api_token, auth_key, data) => dispatch(init_auth(api_token, auth_key, data)),
    clear_auth: () => dispatch(clear_auth()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
