import React from 'react';
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'

const Paging = (props) => {
    return (
        props.pageCount > 1 &&
        <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            // breakLabel={<a style={{cursor:"default"}}>...</a>}
            // breakClassName={"break-me"}
            pageCount={props.pageCount}
            forcePage={props.currentPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={props.handlePageClick}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'} />
    );
};

Paging.propTypes = {
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageClick: PropTypes.func.isRequired
}

export default Paging;