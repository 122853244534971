import qs from 'qs'

import {API_HOST} from './host'

export const requestData = (action, data) => {
    return(
        fetch(API_HOST+action, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: qs.stringify(data)
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())

    )
}

export const requestAuth = (action, api_token) => {
    return(
        fetch(API_HOST+action, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'bearer '+api_token
            },
            body: qs.stringify({ api_token: api_token })
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
    )
};