import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
// import Tabset_user from './tabset-user';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {requestData} from "../../helper/request";
import Swal from "sweetalert2";
import {
    Input,
    Label,
} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup} from "availity-reactstrap-validation";
import ImgCrop from "antd-img-crop";
import {Upload} from "antd";
import 'antd/dist/antd.css';
import {IMG_HOST, MAIN_HOST} from "../../helper/host";
import customToast from "../../notification/customToast";
import '../../notification/toast.css'
import LoadingScreen from '../loadingScreen/loading';

export class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUser        : [],
            fileList        : [],
            loading         : false,
            password        : '',
            firstLoading    : true

        }
        this.handleChange   = this.handleChange.bind(this)
        this.handleSubmit   = this.handleSubmit.bind(this)
        this.confirmUser    = this.confirmUser.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }

    confirmUser() {
        if (this.state.password != ''){
            Swal.fire({
                title: 'Pemberitahuan!',
                text: 'Anda yakin ingin mengganti kata sandi anda menjadi ' + ((this.state.password)) + '?',
                icon: 'warning',
                confirmButtonText: 'Ya',
                showCancelButton: true,
                cancelButtonText: 'Batal',
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.handleSubmit()
                    }
                })
        }else {
            this.handleSubmit()
        }

    }

    handleSubmit () {
        this.setState({
            loading         : true,
        })
        let form = {
            id: this.state.dataUser.id,
            user: this.state.user,
            email: this.state.email,
            password: this.state.password,
            level: this.state.level,
            foto: this.state.foto,
        }
        this.props.request("admin/edit_user", form)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        isLoading   : true,
                        fileList    : [],
                    });Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                    this.props.history.push('/users/list-users')
                }else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            })
    }

    demoAsyncCall() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2500));
    }

    loadData() {
        let formData = {
            id      : this.props.match.params.id,
            auth    : this.props.auth
        }
        this.props.request("admin/detail", formData, 'POST')
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        dataUser        : result,
                        modal           : false,
                        auth            : this.props.auth,
                        isLoading       : false,
                        loading         : false,
                        fileList        : [
                            {
                                uid     : "-1",
                                name    : result.foto,
                                status  : "done",
                                url     : IMG_HOST + 'admin/user/' + result.foto
                            }
                        ],
                        user            : result.user,
                        email           : result.email,
                        level           : result.level,
                        // password        : result.password
                    });

                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.demoAsyncCall().then(() => this.setState({ firstLoading: false }));
    }

    render() {
        const { loading } = this.state;
        const { firstLoading } = this.state;
        if(firstLoading) {
            return <LoadingScreen/>;
        }

        return (
            <Fragment>
                <Breadcrumb title="Edit User" parent="User"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4>Detail Pengguna</h4>
                                </div>
                                <div className="card-body">
                                        <AvForm>

                                            <AvGroup style={{ marginBottom: '10px' }}>
                                                <Label for="user" className="mr-sm-2"><strong> Foto Pengguna *</strong></Label>
                                                <div className="col-md-12" style={{ padding: "inherit" }}>
                                                    <ImgCrop
                                                        useRatio={true}
                                                        width={100}
                                                        height={100}>
                                                        <Upload action="http://localhost:8000/"
                                                                listType="picture-card"
                                                                // style={{width: '200px !important'}}
                                                                fileList={this.state.fileList}
                                                                onRemove={() => {
                                                                    this.setState({foto: null})
                                                                }}
                                                                onChange={(file) => {
                                                                    this.setState({ fileList: file.fileList })

                                                                    if (file.fileList.length > 0) {
                                                                        let files = file.file.originFileObj
                                                                        let reader = new FileReader()

                                                                        reader.readAsDataURL(files)
                                                                        reader.onloadend = () => {
                                                                            this.setState({
                                                                                foto: {
                                                                                    name: file.file.name,
                                                                                    file: reader.result
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                    else {
                                                                        this.setState({ foto: [] })
                                                                    }
                                                                }}
                                                        >
                                                            {this.state.fileList.length >= 1 ? null : "+ Add image"}
                                                        </Upload>
                                                    </ImgCrop>
                                                </div>
                                            </AvGroup>
                                            <AvGroup style={{ marginBottom: '10px' }}>
                                                <Label for="user" className="mr-sm-2"><strong>Nama Pengguna *</strong></Label>
                                                <div className="col-md-12" style={{ padding: "inherit" }}>
                                                    <AvInput
                                                        style={{ width: "inherit" }}
                                                        name="user"
                                                        placeholder="Nama Pengguna.."
                                                        id="user"
                                                        onChange={this.handleChange}
                                                        value={this.state.dataUser.user}
                                                    />
                                                </div>
                                            </AvGroup>
                                            <br/>
                                            <AvGroup style={{ marginBottom: '10px' }}>
                                                <Label for="email" className="mr-sm-2"><strong>Email Pengguna *</strong></Label>
                                                <div className="col-md-12" style={{ padding: "inherit" }}>
                                                    <AvInput
                                                        style={{ width: "inherit" }}
                                                        name="email"
                                                        placeholder="Email Pengguna.."
                                                        id="email"
                                                        onChange={this.handleChange}
                                                        value={this.state.email}
                                                    />
                                                </div>
                                            </AvGroup>
                                            <AvGroup style={{ marginBottom: '10px' }}>
                                                <Label for="password" className="mr-sm-2"><strong>Password Pengguna *</strong></Label>
                                                <div className="col-md-12" style={{ padding: "inherit" }}>
                                                    <AvInput
                                                        style={{ width: "inherit" }}
                                                        type="password"
                                                        name="password"
                                                        placeholder="Kosongkan jika tidak ingin mengganti.."
                                                        id="password"
                                                        onChange={this.handleChange}
                                                        value={this.state.password}
                                                    />
                                                </div>
                                            </AvGroup>
                                            <div className="form-group">
                                                <label>Level</label>
                                                <select
                                                    name="level"
                                                    className="form-control col-xl-12 col-md-12"
                                                    value={this.state.level}
                                                    onChange={(event) => {
                                                        const target = event.target;
                                                        const value  = target.value;
                                                        const name   = target.name;
                                                        this.setState({
                                                            [name]: value
                                                        });
                                                    }}>
                                                    <option value="">Pilih Level</option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="SEO">SEO</option>
                                                </select>
                                            </div>
                                        </AvForm>
                                        <div className="pull-right">
                                            <button type="button" className="btn btn-primary" onClick={this.confirmUser} disabled={loading}>
                                                {loading && (
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                )}
                                                {loading && <span>Simpan</span>}
                                                {!loading && <span>Simpan</span>}
                                            </button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit
