import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { pageNumber } from '../../functions/functionGeneral'
import Swal from "sweetalert2";
import { Loader } from 'react-loaders';
import moment from 'moment';
import 'moment/locale/id'
import {
    Table, ButtonGroup, Button, FormGroup, Input, Col, Label
} from 'reactstrap';
import Select, { components }  from "react-select";
import { Link } from "react-router-dom";
import {DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import Paging from "../../components/paging";
import Spin from "antd/es/spin";
import LabelStatus from "./components/statusPaket";
import format from "format-number";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import './components/form.css';
import {faEdit, faPlus, faRetweet, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class List_Paket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataPaket   : [],
            dataImage   : [],
            paging      : 15,
            perPage     : 0,
            photoIndex  : 0,
            pageCount   : 0,
            keyword     : '',
            status      : '',
            idDel       : '',
            currentPage : 0,
            pilihBulan  : null,
            isLoading   : true,
            modal       : false,
            isOpen      : false,
            visible     : false,
        }
        this.toggle                 = this.toggle.bind(this)
        this.show                   = this.show.bind(this)
        this.handleChange           = this.handleChange.bind(this)
        this.handlePageClick        = this.handlePageClick.bind(this)
        this.handlePaging           = this.handlePaging.bind(this)
        this.handleSearchKeyword    = this.handleSearchKeyword.bind(this)
        this.deleteUser             = this.deleteUser.bind(this)

    }
    toggle() {
        this.setState({
            modal: true
        });
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({currentPage: selected}, () => {
            this.loadData();
        });
    }

    handlePaging(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            dataPaket   :[],
            isLoading   : true,
            [name]      : value
        }, () => {
            this.loadData()
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            [name]: value
        })
    }

    handleSearchKeyword(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            keyword     : value
        })
    }

    show() {
        Swal.fire({
            title: 'Apa anda Yakin?',
            text: "Jika sudah dihapus maka tidak bisa kembali lagi!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor:'#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteUser()
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
            }
        })
        this.setState({
            visible: true
        });
    }

    deleteUser() {

        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Paket Ini?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        paket_id : this.state.idDel
                    }
                   this.props.request("admin/paket/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    refresh() {
        this.setState({
            paging      : 25,
            keyword     : "",
            status      : "",
            pilihBulan  : '',
            dataPaket   : [],
            isLoading   : true,
        }, () => {
            this.loadData()
        })

    }

    loadData() {
        let formData = {
            paket_id    : this.state.paket_id,
            auth        : this.props.auth,
            keyword     : this.state.keyword,
            pilihBulan  : this.state.pilihBulan,
            paket_bulan  : this.state.paket_bulan,
            status      : this.state.status,
            page        : this.state.currentPage + 1,
            paging      : this.state.paging,
        }
        this.props.request("admin/paket/list", formData)
            .then(response => {
                if (response.success) {
                    var dataImage = [];
                    var result = response.data.data;
                    result.map(item =>
                        dataImage.push((item.paket_foto != null ? IMG_HOST +'admin/paket/'+ item.paket_foto : DEFAULT_IMG)),
                    );
                    this.setState({
                        dataPaket   : result,
                        dataImage   : dataImage,
                        modal       : false,
                        auth        : this.props.auth,
                        isLoading   : false,
                    });
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({auth: newProps.auth})
        }
    }

    render() {
        const {isOpen,photoIndex } = this.state;

        const styles = {

            control: base => ({
                ...base,
                minHeight: 30
            }),
            dropdownIndicator: base => ({
                ...base,
                padding: 4
            }),
            clearIndicator: base => ({
                ...base,
                padding: 4
            }),

            valueContainer: base => ({
                ...base,
                padding: '0px 6px'
            }),
            input: base => ({
                ...base,
                margin: 0,
                padding: 0
            }),

            option: styles => ({ ...styles,

            }),
            menu: styles => ({ ...styles,
                width: '300px'
            })

        };
        var no = 0  ;
        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }
        return (
            <Fragment>
                <Breadcrumb title="Daftar Paket" parent="Paket" />
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <Link to="/paket/add-paket" className="btn btn-info" style={{float: "right"}}><FontAwesomeIcon icon={faPlus} style={{fontSize:12}}/> Tambah</Link>
                            <h5>Daftar Paket</h5>
                        </div>
                        <div className="row" style={{marginLeft: '5px'}}>
                            <div className="col-3">
                                <FormGroup style={{marginLeft:11}}>
                                    <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Nama, Kode, Jumlah) </Label>
                                    <Input type="text"
                                           className="mb-2 form-control-sm form-control"
                                           name="keyword"
                                           onChange={this.handleSearchKeyword}
                                           onKeyUp={(event) => {
                                               event.preventDefault()

                                               if (event.which == 13) {
                                                   this.setState({
                                                       isLoading    : true,
                                                       dataPaket    : []
                                                   }, () => {
                                                       this.loadData()
                                                   });
                                               }
                                           }}
                                           value={this.state.keyword}
                                           placeholder="Search.."/>
                                </FormGroup>
                            </div>
                            <div className="col-2">
                                <FormGroup>
                                    <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Filter Berdasarkan Status) </Label>
                                    <Input style={{width:'inherit',height:'32px'}} bsSize="sm" type="select" name="status" value={this.state.status} onChange={this.handlePaging}>
                                        <option value="">- All Status -</option>
                                        <option value="active">Active</option>
                                        <option value="non-active">Non Active</option>
                                    </Input>
                                </FormGroup>
                            </div>
                            <div className="col-3">
                                <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Reset Filter) </Label>
                                <div className="btn-actions-pane-right">
                                    <ButtonGroup size="sm">
                                        <Button caret="true" color="dark"
                                                className={"btn-shadow "}
                                                onClick={() => {
                                                    this.refresh();
                                                }}
                                        ><FontAwesomeIcon icon={faRetweet} style={{fontSize:12}}/> Reset</Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="card-body">
                            <Table>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Tanggal dibuat</th>
                                    <th>Foto</th>
                                    <th>Nama</th>
                                    <th>Deskripsi</th>
                                    <th>Jumlah</th>
                                    <th>Bulan</th>
                                    <th>Harga</th>
                                    <th width={40}>Status</th>
                                    <th className="text-center" width={100}>Aksi</th>
                                </tr>
                                </thead>
                                <tbody>
                                {!this.state.isLoading && this.state.dataPaket.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.dataPaket.map((item, index) => {
                                    no  += 1
                                    return(
                                        <tr key={item.paket_id}>
                                            <th scope="row">
                                                <small>
                                                    {pageNumber(no, this.state.perPage, this.state.currentPage)}
                                                </small>
                                            </th>
                                            <td>
                                                {moment(item.paket_create_date).format('lll')}
                                            </td>
                                            <td>
                                                <ButtonGroup>
                                                    {item.paket_foto != null ? (
                                                        <Col sm={2} key={index}>
                                                            <img onClick={() =>
                                                                this.setState({ isOpen: true, photoIndex: index })
                                                            }
                                                                 width={50}
                                                                 onError={(event)=>{
                                                                     event.target.src = DEFAULT_IMG
                                                                 }}
                                                                 src={item.paket_foto != '' ? IMG_HOST +'admin/paket/'+ item.paket_foto : DEFAULT_IMG} alt='image'/>
                                                        </Col>
                                                    ) : <img  width={50} src={DEFAULT_IMG} alt="image"/>}

                                                </ButtonGroup>
                                            </td>
                                            <td align="center">
                                                <small>
                                                    {item.paket_nama}<br/>
                                                    <strong>
                                                        {item.paket_code}
                                                    </strong>
                                                </small>
                                            </td>
                                            <td>
                                                <small>
                                                    {item.paket_desc}
                                                </small>
                                            </td>
                                            <td>{item.paket_jumlah}</td>
                                            <td align="center">
                                                <small>
                                                    <strong>
                                                        {item.paket_bulan}
                                                        {/*{moment().month( -1).format('MMMM')}*/}
                                                    </strong>
                                                </small>

                                            </td>
                                            <td className="text-right">
                                                <small>
                                                    <strong>
                                                        Rp. {format({prefix: '', integerSeparator: "."})(item.paket_harga)}
                                                    </strong>
                                            </small>
                                            </td>
                                            <td>
                                                {item.paket_premium == '1' && (
                                                    <>
                                                        <LabelStatus status={"premium"}/><br/>
                                                    </>
                                                )}
                                                <LabelStatus status={item.paket_status}/>
                                            </td>

                                            <td align={"center"}>
                                                <a href={"/paket/edit-paket/" + item.paket_id}>
                                                    <FontAwesomeIcon icon={faEdit} style={{fontSize:18, marginRight: 4}}/>
                                                </a>
                                                <a onClick={() => {
                                                    this.setState({
                                                        isLoading: true,
                                                        idDel: item.paket_id
                                                    });
                                                    this.deleteUser()
                                                }}>
                                                    <FontAwesomeIcon icon={faTrash} style={{fontSize:18, color: "red"}}/>
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {
                                    isOpen ?(
                                        <Lightbox
                                            mainSrc={this.state.dataImage[photoIndex]}
                                            onCloseRequest={() => this.setState({ isOpen: false })}
                                        />
                                    ) : null
                                }
                                {this.state.isLoading &&
                                <tr>
                                    <td colSpan="11">
                                        <Spin style={{width: '100%'}}  size="small" tip="Mohon Tunggu..."/>
                                    </td>
                                </tr>
                                }
                                </tbody>
                            </Table>
                        </div>
                        <Paging
                            className="float-right"
                            handlePageClick={this.handlePageClick}
                            pageCount={this.state.pageCount}
                            currentPage={this.state.currentPage}
                        />

                    </div>
                </div>
            </Fragment>

        )
    }
}

export default List_Paket
const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <i className="fa fa-search" aria-hidden="true" />
            </components.DropdownIndicator>
        )
    );
};
