import {
    Home,
    Archive, Image,
    Users, Settings, Globe,
    Briefcase
} from 'react-feather';
export const MENUITEMS = [
    {
        path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    },
    {
        title: 'Perusahaan', icon: Briefcase, type: 'sub', children: [
            { path: '/company/list-company', title: 'Daftar Perusahaan', type: 'link' },
            { path: '/company/list-kritik-saran', title: 'List Kritik & saran', type: 'link' },
            { path: '/company/bookmark-company', title: 'Perusahaan Follow Up', type: 'link' },
            { path: '/company/broadcast-company', title: 'Broadcast Perusahaan', type: 'link' },
        ]
    },
    {
        title: 'Banner', icon: Image, type: 'link', active: false, path: '/banner'
    },
    {
        title: 'Paket', icon: Archive, type: 'link', active: false, path: '/paket/list-paket'
    },
    {
        title: 'Blog', icon: Globe, type: 'link', active: false, path: '/blog/list-blog'
    },
    {
        title: 'Pengguna', icon: Users, type: 'link', active: false, path: '/users/list-users'
    },
    {
        title: 'Pengaturan', icon: Settings, type: 'sub', children: [
            { path: '/pengaturan/general', title: 'General', type: 'link' },
            { path: '/pengaturan/privasi', title: 'Privasi', type: 'link' },
            { path: '/pengaturan/tentang-kami', title: 'Tentang Kami', type: 'link' },
        ]
    },

];

