import React from 'react';
import logo from './logo2.gif';
import './loading.css';
import {Card,CardBody} from 'reactstrap'
import Spin from "antd/es/spin";

class LoadingScreen extends React.Component {

    // fake authentication Promise
    authenticate(){
        return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
    }

    componentDidMount(){
        this.authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if(ele){
                // fade out
                ele.classList.add('available')
                setTimeout(() => {
                    // remove from DOM
                    ele.outerHTML = ''
                }, 2000)
            }
        })
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <div className="wrapper">
                        {/*<img src={logo} alt="logo" className="App-logo"/>*/}
                        <Spin style={{width: '100%'}}  size="small" tip="Mohon Tunggu..."/>
                    </div>
                </header>
            </div>
        );
    }
}

export default LoadingScreen;
