import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { pageNumber } from '../../functions/functionGeneral'
import Swal from "sweetalert2";
import { Loader } from 'react-loaders';
import {App} from './css/app.css'
import moment from 'moment';
import 'moment/locale/id'
import {
    Table, ButtonGroup, Button, FormGroup, Input, Tooltip, Col, Label, UncontrolledTooltip
} from 'reactstrap';
import {requestData} from "../../helper/request";
import { Link } from "react-router-dom";
import {DEFAULT_IMG, IMG_HOST} from "../../helper/host";
import Paging from "../../components/paging";
import Spin from "antd/es/spin";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {clear_auth, set_auth} from "../../reducers/Auth";
import {connect} from "react-redux";
import {Lists} from "../Perusahaan/List";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faTrash, faEdit, faExternalLinkAlt, faPlus, faRetweet, faShare} from "@fortawesome/free-solid-svg-icons";
import FormDateRangePicker from "../form/components/DatePicker/Examples/DateRangeFilter";
import LabelPaket from "../Perusahaan/components/statusPaket";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import LabelStatus from "../Perusahaan/components/statusVerify";
import style from "../Perusahaan/components/button.css";
import Modal from "../Perusahaan/components/Modal";
import {Button1} from "../Perusahaan/components/Button/button";

export class List_User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUser        : [],
            dataImage       : [],
            paging          : 15,
            perPage         : 0,
            photoIndex      : 0,
            pageCount       : 0,
            keyword         :'',
            idDel           : '',
            currentPage     : 0,
            isLoading       : true,
            modal           : false,
            visible         : false,
            auth            : this.props.auth,
            disabledButton  : false,
            isOpen          : false,
            activeShow      : true,
            confirm         : false,
        }
        this.toggle                 = this.toggle.bind(this)
        this.show                   = this.show.bind(this)
        this.handleChange           = this.handleChange.bind(this)
        this.handlePageClick        = this.handlePageClick.bind(this)
        this.handleSearchKeyword    = this.handleSearchKeyword.bind(this)
        this.deleteUser             = this.deleteUser.bind(this)

    }
    toggle() {
        this.setState({
            modal: true
        });
    }

    handlePageClick(data) {
        let selected = data.selected; // zero based index
        this.setState({currentPage: selected}, () => {
            this.loadData();
        });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            [name]: value
        })
    }

    handleSearchKeyword(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            currentPage : 0,
            keyword     : value
        })
    }

    show() {
        Swal.fire({
            title: 'Apa anda Yakin?',
            text: "Jika sudah dihapus maka tidak bisa kembali lagi!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor:'#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.deleteUser()
                Swal.fire(
                    'Terhapus!',
                    'User Berhasil Dihapus',
                    'success'
                )
            }
        })
        this.setState({
            visible: true
        });
    }

    deleteUser() {

        Swal.fire({
            title: 'Pemberitahuan!',
            text: 'Anda yakin ingin menghapus Pengguna?',
            icon: 'warning',
            confirmButtonText: 'Ya',
            showCancelButton: true,
            cancelButtonText: 'Batal',
        })
            .then((result) => {
                if (result.isConfirmed) {
                    let form = {
                        id: this.state.idDel
                    }
                   this.props.request("admin/delete", form, 'POST')
                        .then(response => {
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil!',
                                    text: response.message,
                                    icon: 'success',
                                    showConfirmButton: false,
                                    timer: 800,
                                })
                                this.loadData()
                            }
                        });
                }
            })
    }

    refresh() {
        this.setState({
            paging      : 25,
            keyword     : "",
            dataUser    : [],
            isLoading   : true,
        }, () => {
            this.loadData()
        })

    }

    loadData() {
        let formData = {
            id      : this.state.id,
            auth    : this.props.auth,
            keyword : this.state.keyword,
            page    : this.state.currentPage + 1,
            paging  : this.state.paging,
        }
        this.props.request("admin/list_user", formData)
            .then(response => {
                if (response.success) {
                    var dataImage = [];
                    var result = response.data;

                    result.data.map(item =>
                        dataImage.push((item.foto != '' ? IMG_HOST +'admin/user/'+ item.foto : DEFAULT_IMG)),

                    );
                    this.setState({
                        dataUser    : result.data,
                        dataImage   : dataImage,
                        modal       : false,
                        auth        : this.props.auth,
                        isLoading   : false,
                    });

                }
            });
    }

    componentDidMount() {
        this.loadData()
    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({auth: newProps.auth})
        }
    }

    render() {
        const {isOpen,photoIndex } = this.state;
        var no = 0  ;
        const stylefoto = {
            maxHeight: '50px',
            maxWidth: '50px',
        }

        if(['Admin'].indexOf(this.props.user.level) >= 0) {
            return (
                <Fragment>
                    <Breadcrumb title="Daftar Pengguna" parent="Pengguna" />
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header">
                                <Link to="/users/add-users" className="btn btn-info" style={{float: "right"}}><FontAwesomeIcon icon={faPlus} style={{fontSize:12}}/> Tambah</Link>
                                <h5>Daftar Pengguna</h5>
                            </div>
                            <div className="row" style={{marginLeft: '5px'}}>
                                <div className="col-3">
                                    <FormGroup style={{marginLeft:11}}>
                                        <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Nama,Email) </Label>
                                        <Input type="text"
                                               className="mb-2 form-control-sm form-control"
                                               name="keyword"
                                               onChange={this.handleSearchKeyword}
                                               onKeyUp={(event) => {
                                                   event.preventDefault()

                                                   if (event.which == 13) {
                                                       this.setState({
                                                           dataUser    : [],
                                                           isLoading   : true,
                                                       }, () => {
                                                           this.loadData()
                                                       });
                                                   }
                                               }}
                                               value={this.state.keyword}
                                               placeholder="Search.."/>
                                    </FormGroup>
                                </div>
                                <div className="col-3">
                                    <Label style={{fontSize: "8px", marginBottom: "1px"}}>(Reset Filter) </Label>
                                    <div className="btn-actions-pane-right">
                                        <ButtonGroup size="sm">
                                            <Button caret="true" color="dark"
                                                    className={"btn-shadow "}
                                                    onClick={() => {
                                                        this.refresh();
                                                    }}
                                            ><i className="fa fa-refresh"></i> Reset</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Foto</th>
                                        <th>Tanggal dibuat</th>
                                        <th>Nama</th>
                                        <th>Email</th>
                                        <th>Level</th>
                                        <th className="text-center">Aksi</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {!this.state.isLoading && this.state.dataUser.length === 0 ? <td colSpan={9}>Data Kosong...</td> :this.state.dataUser.map((item, index) => {
                                        no += 1
                                        return(
                                            <tr key={item.id}>
                                                <th scope="row">{pageNumber(no, this.state.perPage, this.state.currentPage)}</th>
                                                <td>
                                                    <ButtonGroup>
                                                        {item.foto != null ? (
                                                            <Col sm={2} key={index}>
                                                                <img onClick={() => this.setState({ isOpen: true, photoIndex: index })}
                                                                     width={50}
                                                                     onError={(event)=>{
                                                                         event.target.src = DEFAULT_IMG
                                                                     }}
                                                                     src={item.foto != '' ? IMG_HOST +'admin/user/'+ item.foto : DEFAULT_IMG} alt='image'/>
                                                            </Col>
                                                        ) : <small>{DEFAULT_IMG}</small>}

                                                    </ButtonGroup>
                                                </td>
                                                <td>{moment(item.created_at).format('lll')}</td>
                                                <td>{item.user}</td>
                                                <td>{item.email}</td>
                                                <td>{item.level}</td>
                                                <td className="text-center">
                                                    <a href={"/users/edit-users/" + item.id}>
                                                        <FontAwesomeIcon icon={faEdit} style={{fontSize:18}}/>
                                                    </a>
                                                    <a onClick={() => {
                                                        this.setState({
                                                            isLoading: true,
                                                            idDel: item.id
                                                        });
                                                        this.deleteUser()
                                                    }}><FontAwesomeIcon icon={faTrash} style={{fontSize:18, color: "red", marginLeft: 4}}/></a>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                    {
                                        isOpen ?(
                                            <Lightbox
                                                mainSrc={this.state.dataImage[photoIndex]}
                                                onCloseRequest={() => this.setState({ isOpen: false })}
                                            />
                                        ) : null
                                    }

                                    {this.state.isLoading &&
                                    <tr>
                                        <td colSpan="6">
                                            <Spin style={{width: '100%'}}  size="small" tip="Mohon Tunggu..."/>
                                        </td>
                                    </tr>
                                    }

                                    </tbody>
                                </Table>
                                <Paging
                                    className="float-right"
                                    handlePageClick={this.handlePageClick}
                                    pageCount={this.state.pageCount}
                                    currentPage={this.state.currentPage}
                                />
                            </div>

                        </div>
                    </div>
                </Fragment>
            )
        }else{
            return (
                <Fragment>
                    <h5 className="text-center pt-4">Anda tidak punya akses ke halaman ini.</h5>
                </Fragment>
            )
        }

    }
}

const mapStateToProps = state => ({
    user: state.Auth.user,
    api_token: state.Auth.api_token
});

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth()),
    set_auth: (api_token, data) => dispatch(set_auth(api_token, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(List_User);
