import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import app from './css/app.css'
import InputCurrency from "./../common/currency-input"
import {
    Input,Label,Row,InputGroup,InputGroupAddon
} from 'reactstrap';
import Swal from "sweetalert2";
import { AvForm, AvGroup, AvInput, AvRadioGroup} from "availity-reactstrap-validation";
import {IMG_HOST} from "../../helper/host";
import {Upload} from "antd";
import ImgCrop from "antd-img-crop";
import customToast from "../../notification/customToast";
import '../../notification/toast.css'
import LoadingScreen from '../loadingScreen/loading';

export class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataPaket       : [],
            fileList        : [],
            paket_foto      : '',
            paket_id        : '',
            paket_nama      : '',
            paket_code      : '',
            paket_jumlah    : '',
            paket_bulan     : '',
            paket_harga     : '',
            paket_desc      : '',
            paket_status    : '',
            paket_premium    : '',
            isLoading       : true,
            firstLoading    : true,
            loading         : false,
            auth            : this.props.auth,
            disabledButton  : false,

        }
        this.handleChangePrice      = this.handleChangePrice.bind(this)
        this.handleChange           = this.handleChange.bind(this)
        this.handleSubmit           = this.handleSubmit.bind(this)
    }

    handleChangePrice(event, maskedvalue) {
        const target = event.target;
        const name = target.name;

        this.setState({ [name]: maskedvalue });
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }



    handleSubmit = () => {
        this.setState({
            loading         : true,
        })
        let form = {
            paket_id        : this.state.dataPaket.paket_id,
            paket_nama      : this.state.paket_nama,
            paket_code      : this.state.paket_code,
            paket_jumlah    : this.state.paket_jumlah,
            paket_bulan     : this.state.paket_bulan,
            paket_harga     : this.state.paket_harga,
            paket_desc      : this.state.paket_desc,
            paket_foto      : this.state.paket_foto,
            paket_status    : this.state.paket_status,
            paket_premium    : this.state.paket_premium,
        }

        this.props.request("admin/paket/update", form,'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading       : true,
                        paket_nama      : '',
                        paket_code      : '',
                        paket_jumlah    : '',
                        paket_bulan     : '',
                        paket_harga     : '',
                        paket_desc      : '',
                        paket_foto      : '',
                        fileList        : [],

                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                    this.props.history.push('/paket/list-paket')
                }else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }

    demoAsyncCall() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2500));
    }

    loadData() {
        let formData = {
            paket_id    : this.props.match.params.id,
            auth        : this.props.auth,

        }
        this.props.request("admin/paket/detail", formData)
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        dataPaket   : result,
                        fileList       : [
                            {
                                uid: "-1",
                                name: result.paket_foto,
                                status: "done",
                                url: IMG_HOST +'admin/paket/'+ result.paket_foto
                            }
                        ],
                        modal           : false,
                        auth            : this.props.auth,
                        isLoading       : false,
                        loading         : false,
                        paket_nama      : result.paket_nama,
                        paket_code      : result.paket_code,
                        paket_jumlah    : result.paket_jumlah,
                        paket_bulan     : result.paket_bulan,
                        paket_harga     : result.paket_harga,
                        paket_desc      : result.paket_desc,
                        paket_status    : result.paket_status,
                        paket_premium    : result.paket_premium,
                    });
                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.demoAsyncCall().then(() => this.setState({ firstLoading: false }));
    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({auth: newProps.auth})
        }
    }



    render() {
        const { loading } = this.state;
        const { firstLoading } = this.state;
        if(firstLoading) {
            return <LoadingScreen/>;
        }

        return (
            <Fragment>
                <Breadcrumb title="Edit Paket" parent="Paket"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <AvForm className="needs-validation user-add" noValidate="">
                                        <h4>Edit Paket</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Foto Paket</label>
                                            <div className="col xl-8 col-md-7">
                                                <ImgCrop
                                                    useRatio={true}
                                                    width={150}
                                                    height={150}>
                                                    <Upload action="http://localhost:8000/"
                                                            listType="picture-card"
                                                            style={{width: '200px !important'}}
                                                            fileList={this.state.fileList}
                                                            onChange={(file) => {
                                                                this.setState({ fileList: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            paket_foto: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ foto: [] })
                                                                }
                                                            }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null : "+ Add image"}
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Nama Paket</label>
                                            <input name="paket_nama" className="form-control col-xl-8 col-md-7" placeholder="Nama Paket..."
                                                   type="text" required="" onChange={this.handleChange} value={this.state.paket_nama}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Kode Paket</label>
                                            <input name="paket_code" className="form-control col-xl-8 col-md-7" placeholder="Kode Paket.."
                                                   type="text" required="" onChange={this.handleChange} value={this.state.paket_code}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Jumlah Karyawan</label>
                                            <input name="paket_jumlah" className="form-control col-xl-8 col-md-7" placeholder="Jumlah Karyawan..."
                                                   type="text" required="" onChange={this.handleChange} value={this.state.paket_jumlah}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Bulan Paket</label>
                                            <input name="paket_bulan" className="form-control col-xl-8 col-md-7" placeholder="Rentang Bulan Paket..."
                                                   type="text" required="" onChange={this.handleChange} value={this.state.paket_bulan}/>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Harga Paket</label>
                                            <div>
                                                <InputGroup style={app}>
                                                    <InputGroupAddon addonType="prepend">
                                                        <div className="input-group-text">
                                                            <Label >Rp</Label>
                                                        </div>
                                                    </InputGroupAddon>
                                                    <InputCurrency
                                                        placeholder={"0"}
                                                        value={this.state.paket_harga}
                                                        onChange={(value) => {this.setState({paket_harga: value})}}
                                                    />
                                                </InputGroup>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Status Paket</label>
                                            <AvGroup style={{ marginBottom: '10px',marginTop:'10px' }}>
                                                <div className="col-md-12" style={{ padding: "inherit" }}>
                                                    <AvRadioGroup inline name="paket_status">
                                                        <Row style={{ marginLeft: '1px' }}>
                                                            <AvInput
                                                                id="active"
                                                                style={{ marginLeft: '5px'}}
                                                                type="radio"
                                                                name="paket_status"
                                                                checked={this.state.paket_status === 'active'}
                                                                onChange={this.handleChange}
                                                                value="active"
                                                            /><span style={{ marginLeft: '25px'}} className="badge badge-success">Active</span>
                                                        </Row>
                                                        <br/>
                                                        <Row style={{ marginLeft: '1px' }}>
                                                            <AvInput
                                                                style={{ marginLeft: '5px'}}
                                                                id="non-active"
                                                                type="radio"
                                                                name="paket_status"
                                                                checked={this.state.paket_status === 'non-active'}
                                                                onChange={this.handleChange}
                                                                value="non-active"
                                                            /><span style={{backgroundColor:'#6c757d', marginLeft: '25px'}} className="badge badge-secondary">Non-Active</span>
                                                        </Row>
                                                    </AvRadioGroup>
                                                </div>
                                            </AvGroup>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Akses Premium</label>
                                            <AvGroup style={{ marginBottom: '10px',marginTop:'10px' }}>
                                                <div className="col-md-12" style={{ padding: "inherit" }}>
                                                    <AvRadioGroup inline name="paket_premium">
                                                        <Row style={{ marginLeft: '1px' }}>
                                                            <AvInput
                                                                id="1"
                                                                style={{ marginLeft: '5px'}}
                                                                type="radio"
                                                                name="paket_premium"
                                                                checked={this.state.paket_premium === '1'}
                                                                onChange={this.handleChange}
                                                                value="1"
                                                            /><span style={{ marginLeft: '25px'}} className="badge badge-success">Ya</span>
                                                        </Row>
                                                        <br/>
                                                        <Row style={{ marginLeft: '1px' }}>
                                                            <AvInput
                                                                style={{ marginLeft: '5px'}}
                                                                id="0"
                                                                type="radio"
                                                                name="paket_premium"
                                                                checked={this.state.paket_premium === '0'}
                                                                onChange={this.handleChange}
                                                                value="0"
                                                            /><span style={{backgroundColor:'#6c757d', marginLeft: '25px'}} className="badge badge-secondary">Tidak</span>
                                                        </Row>
                                                    </AvRadioGroup>
                                                </div>
                                            </AvGroup>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Deskripsi Paket</label>
                                            <div>
                                                <Input rows={4}
                                                       cols={89}
                                                       type="textarea"
                                                       name="paket_desc"
                                                       id="paket_desc"
                                                       onChange={this.handleChange}
                                                       value={this.state.paket_desc} />
                                            </div>
                                        </div>
                                    </AvForm>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Simpan</span>}
                                            {!loading && <span>Simpan</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit
