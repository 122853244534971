import React, { Component } from 'react';
import {Home} from 'react-feather';
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {BreadcrumbItem} from "reactstrap";


export class Breadcrumb extends Component {
    render() {
        return (
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="page-header-left">
                                    <h3>{this.props.title}
                                    <small>Presensi Admin panel</small>
                                    </h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ol className="breadcrumb pull-right">
                                    <li className="breadcrumb-item">
                                        <a href="/">
                                            <FontAwesomeIcon icon={faHome}/> Dashboards
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item"><a href={this.props.parentLink}>{this.props.parent}</a></li>
                                    <li className="breadcrumb-item active">{this.props.title}</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Breadcrumb
