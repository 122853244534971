import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUsers, faBuilding, faGlobe, faArchive} from '@fortawesome/free-solid-svg-icons'
import LoadingScreen from "./loadingScreen/loading";
import {Pie} from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // paging          : 5,
            // perPage         : 0,
            // pageCount       : 0,
            pengguna        : '',
            perusahaan      : '',
            paket           : '',
            blog            : '',
            firstLoading    : true,
        }

    }

    // demoAsyncCall() {
    //     return new Promise((resolve) => setTimeout(() => resolve(), 2500));
    // }

    loadData() {
        let formData = {
            auth    : this.props.auth
        };
        this.props.request('admin/dashboard/jumlah', formData, 'POST')
            .then((response) => {
                if (response.success) {
                    this.setState({
                        pengguna    : response.jumlah_pengguna,
                        perusahaan  : response.jumlah_perusahaan,
                        paket      : response.jumlah_paket,
                        blog      : response.jumlah_blog,
                    })
                }
                this.setState({ isLoading: false })
            })
    }

    componentDidMount() {
        this.loadData();
        // this.demoAsyncCall().then(() => this.setState({ firstLoading: false }));
    }

    render() {
        var paketPerusahaan = {
            labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
            datasets: [
                {
                    label: '',
                    data: [12, 19, 3, 5, 2, 3],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
        // const { firstLoading } = this.state;
        // if(firstLoading) {
        //     return <LoadingScreen/>;
        // }
        return (

            <Fragment>
                <Breadcrumb title="Dashboard" parent="Dashboard" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-6 xl-50">
                            <a  href={"/users/list-users"}>
                                <div className="card o-hidden widget-cards">
                                    <div className="bg-warning card-body">
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center"><FontAwesomeIcon className="font-warning" icon={faUsers} style={{fontSize:15}}/></div>
                                            </div>
                                            <div className="media-body col-8"><span className="m-0">Pengguna</span>
                                                <h3 className="mb-0"><label>{this.state.pengguna}</label><small> total </small></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <a  href={"/company/list-company"}>
                                <div className="card o-hidden  widget-cards">
                                    <div className="bg-secondary card-body">
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center"><FontAwesomeIcon className="font-secondary" icon={faBuilding} style={{fontSize:15}}/></div>
                                            </div>
                                            <div className="media-body col-8"><span className="m-0">Perusahaan</span>
                                                <h3 className="mb-0"> <label>{this.state.perusahaan}</label><small> total </small></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <a  href={"/paket/list-paket"}>
                                <div className="card o-hidden widget-cards">
                                    <div className="bg-primary card-body">
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center"><FontAwesomeIcon className="font-primary" icon={faArchive} style={{fontSize:15}}/></div>
                                            </div>
                                            <div className="media-body col-8"><span className="m-0">Paket</span>
                                                <h3 className="mb-0"> <label>{this.state.paket}</label><small> total </small></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-xl-3 col-md-6 xl-50">
                            <a  href={"/blog/list-blog"}>
                                <div className="card o-hidden widget-cards">
                                    <div className="card-body" style={{backgroundColor: '#6c757d'}}>
                                        <div className="media static-top-widget row">
                                            <div className="icons-widgets col-4">
                                                <div className="align-self-center text-center"><FontAwesomeIcon color='gray' icon={faGlobe} style={{fontSize:15}}/></div>
                                            </div>
                                            <div className="media-body font-light col-8"><span className="m-0">Blog</span>
                                                <h3 className="mb-0"> <label>{this.state.blog}</label><small> total </small></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    {/*<div className="row">*/}
                    {/*    <div className="card p-2">*/}
                    {/*        <div className="card-body">*/}
                    {/*            <h4>Grafik Paket Perusahaan</h4>*/}
                    {/*            <Pie data={paketPerusahaan} />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </Fragment>

        )
    }
}
// javascript:void(0)

export default Dashboard
