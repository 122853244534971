//Local
// export const API_HOST = "http://localhost:8000/"
// export const MAIN_HOST = 'http://localhost:8000/'
// export const API_EXPORT = "http://localhost:8000/"
// export const IMG_HOST       = "http://localhost/api_presensi_new/public/media/"
// export const DEFAULT_IMG    = MAIN_HOST+'media/image/default-img.png'

// export const BACKEND_API_HOST = "http://localhost:8000/backend/"

// Development
// export const API_HOST = "https://servicesdev.presensi.co.id/"
// export const MAIN_HOST = "https://servicesdev.presensi.co.id/"
// export const IMG_HOST       = "https://servicesdev.presensi.co.id/media/"
// export const DEFAULT_IMG    = MAIN_HOST + 'media/logo-default-user.png'

// Production
export const API_HOST       = "https://services.presensi.co.id/"
export const MAIN_HOST      = 'https://services.presensi.co.id/'
export const API_EXPORT     = "https://services.presensi.co.id/"
export const IMG_HOST       = "https://services.presensi.co.id/media/"
export const DEFAULT_IMG    = MAIN_HOST+'media/image/default-img.png'
//
export const THUMB_HOST     = MAIN_HOST+'media/image/thumb/'

export const MITRA_HOST     = "https://mitra.presensi.co.id"
