import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
// import Tabset_user from './tabset-user';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {requestData} from "../../helper/request";
import Swal from "sweetalert2";
import {IMG_HOST} from "../../helper/host";
import {Upload} from "antd";
import ImgCrop from "antd-img-crop";
import customToast from "../../notification/customToast";
import '../../notification/toast.css'

export class Add_User extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUser        : [],
            fileList        : [],
            paging          : 25,
            perPage         : 0,
            pageCount       : 0,
            keyword         : '',
            idDel           : '',
            currentPage     : 0,
            foto            : '',
            isLoading       : true,
            auth            : this.props.auth,
            disabledButton  : false,
            loading         : false,
            email           : "",
            password        : '',
            confirm_password:'',
            level           :'',
            startDate       : new Date()

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        })
    }



    handleSubmit = () => {
        this.setState({
            loading         : true,
        })
        let form = {
        user: this.state.user,
        email: this.state.email,
        password: this.state.password,
        confirm_password: this.state.confirm_password,
        level: this.state.level,
        foto: this.state.foto
        }

        this.props.request("admin/register", form,'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: true,
                        foto: '',
                        fileList: [],

                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData()
                    this.props.history.push('/users/list-users')
                }else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }

    loadData() {
        let formData = {
            id      : this.state.id,
            auth    : this.props.auth,

        }
        this.props.request("admin/list_user", formData)
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        dataUser    : result.data,
                        modal       : false,
                        auth        : this.props.auth,
                        isLoading   : false,
                        loading     : false,
                    });

                }
            });
    }

    componentDidMount() {
        this.loadData()
    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({auth: newProps.auth})
        }
    }



    render() {
        const { loading } = this.state;
        return (
            <Fragment>
                <Breadcrumb title="Tambah Pengguna" parent="Pengguna"/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="needs-validation user-add" noValidate="">
                                        <h4>Tambah Pengguna</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Foto Pengguna</label>
                                            <div className="col xl-8 col-md-7">
                                                <ImgCrop
                                                    useRatio={true}
                                                    width={100}
                                                    height={100}>
                                                    <Upload action="http://localhost:8000/"
                                                            listType="picture-card"
                                                            style={{width: '200px !important'}}
                                                            fileList={this.state.fileList}
                                                            onChange={(file) => {
                                                                this.setState({ fileList: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            foto: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ foto: [] })
                                                                }
                                                            }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null : "+ Add image"}
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Username</label>
                                            <input name="user" className="form-control col-xl-8 col-md-7" placeholder="Nama Pengguna..."
                                                   type="text" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Email</label>
                                            <input name="email" className="form-control col-xl-8 col-md-7" placeholder="e.x 12345@..."
                                                   type="email" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Password</label>
                                            <input name="password" className="form-control col-xl-8 col-md-7" placeholder="Password"
                                                   type="password" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Ulangi Password</label>
                                            <input name="confirm_password" className="form-control col-xl-8 col-md-7" placeholder="Ulangi Password anda..."
                                                   type="password" required="" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4">Level</label>
                                            <select
                                                name="level"
                                                className="form-control col-xl-8 col-md-7"
                                                value={this.state.level}
                                                onChange={(event) => {
                                                    const target = event.target;
                                                    const value  = target.value;
                                                    const name   = target.name;
                                                    this.setState({
                                                        [name]: value
                                                    });
                                                }}>
                                                <option value="">Pilih Level</option>
                                                <option value="Admin">Admin</option>
                                                <option value="SEO">SEO</option>
                                            </select>
                                        </div>
                                    </form>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Simpan</span>}
                                            {!loading && <span>Simpan</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Add_User
