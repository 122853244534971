import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import {Row} from 'reactstrap';
import Swal from "sweetalert2";
import { AvForm, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import customToast from "../../notification/customToast";
import '../../notification/toast.css'
import CKEditor from "react-ckeditor-component";
import {IMG_HOST} from "../../helper/host";

export class SetBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataBlog: [],
            fileList: [],
            image_banner: '',
            title: '',
            desc: '',
            short: '',
            keyword: '',
            status: 'active',
            isLoading: true,
            auth: this.props.auth,
            disabledButton: false,
            loading: false,

        }
        this.handleChangePrice = this.handleChangePrice.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.onEditorStateChange = this.onEditorStateChange.bind(this);

    }
    onEditorStateChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            desc: newContent
        })
    };
    handleChangePrice(event, maskedvalue) {
        const target = event.target;
        const name = target.name;

        this.setState({ [name]: maskedvalue });
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSubmit = () => {
        this.setState({
            loading: true,
        })
        let form = {
            url: this.state.url_banner,
            image: this.state.image_banner,
        }

        this.props.request("admin/banner/edit", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        isLoading: true,
                        title: '',
                        desc: '',
                        status: '',
                        short: '',
                        blog_image: '',
                        fileList: [],
                    })
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.props.history.push('/dashboard')
                } else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }


    loadData() {
        let formData = {
            auth: this.props.auth,
        }
        this.props.request("backend/banner", formData)
            .then(response => {
                if (response.success) {
                    var result = response.data
                    this.setState({
                        fileList: [
                            {
                                uid: "-1",
                                name: result.setting_value,
                                status: "done",
                                url: IMG_HOST + 'banner/' + result.setting_value
                            }
                        ],
                        image_banner: result.setting_value,
                        url_banner: response.dataurl.setting_value,
                        isLoading: false,
                        loading: false,
                    });
                }
            });
    }

    componentDidMount() {
        this.loadData()
    }

    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }

    render() {
        const { loading } = this.state;
        const defaultValues = {
            status: 'active'
        }
        return (
            <Fragment>
                <Breadcrumb title="Banner" parent="Set Banner" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <AvForm model={defaultValues} className="needs-validation user-add" noValidate="">
                                        <h4>Set Banner</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Foto Banner</label>
                                            <div className="col xl-8 col-md-7">
                                                <ImgCrop
                                                    useRatio={true}
                                                    width={1000}
                                                    height={1000}>
                                                    <Upload action="http://localhost:8000/"
                                                            listType="picture-card"
                                                            style={{ width: '200px !important' }}
                                                            fileList={this.state.fileList}
                                                            onChange={(file) => {
                                                                this.setState({ fileList: file.fileList })

                                                                if (file.fileList.length > 0) {
                                                                    let files = file.file.originFileObj
                                                                    let reader = new FileReader()

                                                                    reader.readAsDataURL(files)
                                                                    reader.onloadend = () => {
                                                                        this.setState({
                                                                            image_banner: {
                                                                                name: file.file.name,
                                                                                file: reader.result
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    this.setState({ image_banner: [] })
                                                                }
                                                            }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null : "+ Add image"}
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> URL Banner</label>
                                            <input name="url_banner" className="form-control col-xl-8 col-md-7" placeholder="URL..."
                                                   type="text" required="" value={this.state.url_banner} onChange={this.handleChange} />
                                        </div>
                                    </AvForm>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Simpan</span>}
                                            {!loading && <span>Simpan</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default SetBanner
