import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import Swal from "sweetalert2";
import {
    Input, Label, Row,
} from 'reactstrap';
import { IMG_HOST, MAIN_HOST } from "../../helper/host";
import { Upload } from "antd";
import LabelStatus from './components/statusVerify'
import { AvForm, AvGroup, AvInput, } from "availity-reactstrap-validation";
import ImgCrop from "antd-img-crop";
import 'antd/dist/antd.css';
import style from './components/form.css'
import { toast } from "react-toastify";
import customToast from "../../notification/customToast";
import LoadingScreen from '../loadingScreen/loading';
import Select from "react-select";

export class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataCompany: [],
            fileList: [],
            Paket: [],
            id: '',
            perusahaan_id: '',
            status: '',
            perusahaan_password: '',
            isLoading: true,
            firstLoading: true,
            loading: false,
            perusahaan_nama: "",
            perusahaan_email: '',
            perusahaan_user: '',
            perusahaan_alamat: '',
            perusahaan_telp: '',
            perusahaan_bidang: '',
            pw_user_id: '',
            pw_user_value: '',
            pilihPaket: null,
            paket_id: 0,
            paket_name: "",
            langganan_pending_date: ""


        }
        this.handleChange = this.handleChange.bind(this)
        this.handleEditSubmit = this.handleEditSubmit.bind(this)
        this.handleSelectPaket = this.handleSelectPaket.bind(this)
        this.handleSelectPassword = this.handleSelectPassword.bind(this)
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        })
    }

    handleSelectPaket = (pilihPaket) => {
        this.setState({
            pilihPaket,
            paket_id: pilihPaket.value,
            paket_nama: pilihPaket.label,
        });
    };

    handleSelectPassword = (item) => {
        this.setState({
            pw_user_id: item.value,
        });
    };

    handleEditSubmit() {
        this.setState({
            loading: true,
        })
        let form = {
            id: this.state.dataCompany.perusahaan_id,
            perusahaan_nama: this.state.perusahaan_nama,
            perusahaan_email: this.state.perusahaan_email,
            paket_nama: this.state.paket_nama,
            paket_id: this.state.paket_id,
            perusahaan_password: this.state.perusahaan_password,
            perusahaan_user: this.state.perusahaan_user,
            perusahaan_alamat: this.state.perusahaan_alamat,
            perusahaan_telp: this.state.perusahaan_telp,
            status: this.state.status,
            perusahaan_bidang: this.state.perusahaan_bidang,
            perusahaan_foto: this.state.perusahaan_foto,
            langganan_pending_date: this.state.langganan_pending_date
            // confirm_password: this.state.confirm_password,
        }

        this.props.request("admin/perusahaan/edit", form)
            .then(response => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        isLoading: true,
                        fileList: [],
                        status: '',
                        paket_nama: '',
                        pilihPaket: null
                    });
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData();
                    this.props.history.push('/company/list-company')
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }

    handleEditPasswordSubmit() {
        this.setState({
            loading: true,
        })
        let form = {
            user: this.state.pw_user_id,
            password: this.state.pw_user_value,
        }

        this.props.request("admin/perusahaan/update-password-user", form)
            .then(response => {
                if (response.success) {
                    toast['success'](response.message)
                    this.setState({
                        isLoading: true,
                        loading: true,
                    });
                    Swal.fire({
                        title: 'Berhasil!',
                        text: response.message,
                        icon: 'success'
                    })
                    this.loadData();
                }
                else {
                    if (Array.isArray(response.message)) {
                        response.message.map(item => {
                            customToast.error(item)
                            return true
                        })
                    } else {
                        customToast.error(response.message)
                    }
                    this.setState({ isLoading: false })
                }
                if (response.failed) {
                    Swal.fire({
                        title: 'Gagal!',
                        text: response.failed,
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    })
                }
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            });
    }

    demoAsyncCall() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2500));
    }

    loadData() {
        let formData = {
            id: this.props.match.params.id,
            auth: this.props.auth
        }
        this.props.request("admin/perusahaan/detail", formData)
            .then(response => {
                if (response.success) {
                    var result = response.data;
                    var paket = response.paket;
                    this.setState({
                        dataCompany: result,
                        Paket: paket,
                        modal: false,
                        loading: false,
                        fileList: [
                            {
                                uid: "-1",
                                name: result.perusahaan_foto,
                                status: "done",
                                url: IMG_HOST + 'admin/perusahaan/' + result.perusahaan_foto
                            }
                        ],
                        langganan_pending_date: result.langganan.langganan_pending_date,
                        paket_nama: result.langganan.paket_nama,
                        paket_id: result.langganan.paket_id,
                        perusahaan_id: result.perusahaan_id,
                        perusahaan_nama: result.perusahaan_nama,
                        perusahaan_alamat: result.perusahaan_alamat,
                        perusahaan_telp: result.perusahaan_telp,
                        perusahaan_bidang: result.perusahaan_bidang,
                        perusahaan_email: result.perusahaan_email,
                        perusahaan_user: result.perusahaan_user,
                        status: result.status,
                    });

                }
            });
    }

    componentDidMount() {
        this.loadData()
        this.demoAsyncCall().then(() => this.setState({ firstLoading: false }));

    }
    componentWillReceiveProps(newProps) {
        if (newProps.auth !== this.state.auth) {
            this.setState({ auth: newProps.auth })
        }
    }



    render() {
        const { loading } = this.state;
        const { firstLoading } = this.state;
        if (firstLoading) {
            return <LoadingScreen />;
        }
        return (
            <Fragment>
                <Breadcrumb title="Edit Perusahaan" parentLink="/company/list-company" parent="Perusahaan" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <AvForm className="needs-validation user-add" noValidate="">
                                        <h4>Edit Perusahaan</h4>
                                        <div className="form-group row">
                                            <label className="col-xl-3 col-md-4"><span>*</span> Foto Perusahaan</label>
                                            <div className="col xl-8 col-md-7">
                                                <ImgCrop
                                                    useRatio={true}
                                                    width={100}
                                                    height={100}>
                                                    <Upload action="http://localhost:8000/"
                                                        listType="picture-card"
                                                        style={{ width: '200px !important' }}
                                                        fileList={this.state.fileList}
                                                        onChange={(file) => {
                                                            this.setState({ fileList: file.fileList })

                                                            if (file.fileList.length > 0) {
                                                                let files = file.file.originFileObj
                                                                let reader = new FileReader()

                                                                reader.readAsDataURL(files)
                                                                reader.onloadend = () => {
                                                                    this.setState({
                                                                        perusahaan_foto: {
                                                                            name: file.file.name,
                                                                            file: reader.result
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                            else {
                                                                this.setState({ perusahaan_foto: [] })
                                                            }
                                                        }}
                                                    >
                                                        {this.state.fileList.length >= 1 ? null : "+ Add image"}
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_nama" className="mr-sm-2"><strong>Nama Perusahaan *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <AvInput
                                                    style={{ width: "inherit" }}
                                                    name="perusahaan_nama"
                                                    placeholder="Nama Perusahaan.."
                                                    id="perusahaan_nama"
                                                    onChange={this.handleChange}
                                                    value={this.state.perusahaan_nama}
                                                />
                                            </div>
                                        </AvGroup>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_alamat" className="mr-sm-2"><strong>Alamat Perusahaan *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <AvInput
                                                    style={{ width: "inherit" }}
                                                    name="perusahaan_alamat"
                                                    placeholder="Nama Perusahaan.."
                                                    id="perusahaan_alamat"
                                                    onChange={this.handleChange}
                                                    value={this.state.perusahaan_alamat}
                                                />
                                            </div>
                                        </AvGroup>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_telp" className="mr-sm-2"><strong>Nomor Telp. Perusahaan *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <AvInput
                                                    style={{ width: "inherit" }}
                                                    name="perusahaan_telp"
                                                    placeholder="Nomor Telp Perusahaan.."
                                                    id="perusahaan_telp"
                                                    onChange={this.handleChange}
                                                    value={this.state.perusahaan_telp}
                                                />
                                            </div>
                                        </AvGroup>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_bidang" className="mr-sm-2"><strong>Bidang Perusahaan *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <AvInput
                                                    style={{ width: "inherit" }}
                                                    name="perusahaan_bidang"
                                                    placeholder="Bidang perusahaan.."
                                                    id="perusahaan_bidang"
                                                    onChange={this.handleChange}
                                                    value={this.state.perusahaan_bidang}
                                                />
                                            </div>
                                        </AvGroup>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_bidang" className="mr-sm-2"><strong>Pilih Paket *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <Select
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    // value={this.state.pilihPaket}
                                                    onChange={this.handleSelectPaket}
                                                    options={
                                                        this.state.Paket.map((item, index) => (
                                                            {
                                                                key: index,
                                                                value: item.paket_id,
                                                                label: item.paket_nama + ' - ' + ' (' + item.paket_jumlah + ' orang) ' + '-' + ' (' + item.paket_bulan + ' bulan) ',
                                                            }
                                                        )
                                                        )
                                                    }
                                                    defaultValue={{
                                                        value: this.state.dataCompany.langganan != null ? this.state.dataCompany.langganan.paket_id : '',
                                                        label: this.state.dataCompany.langganan != null ? this.state.dataCompany.langganan.paket_nama : ''
                                                    }}
                                                />
                                            </div>
                                        </AvGroup>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_user" className="mr-sm-2"><strong>Pengguna Perusahaan *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <AvInput
                                                    style={{ width: "inherit" }}
                                                    name="perusahaan_user"
                                                    placeholder="Pengguna Perusahaan.."
                                                    id="perusahaan_user"
                                                    onChange={this.handleChange}
                                                    value={this.state.perusahaan_user}
                                                />
                                            </div>
                                        </AvGroup>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_email" className="mr-sm-2"><strong>Email Perusahaan *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <AvInput
                                                    style={{ width: "inherit" }}
                                                    name="perusahaan_email"
                                                    placeholder="Email Perusahaan.."
                                                    id="perusahaan_email"
                                                    onChange={this.handleChange}
                                                    value={this.state.perusahaan_email}
                                                />
                                            </div>
                                        </AvGroup>

                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="status" className="mr-sm-2"><strong>Status Perusahaan *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <LabelStatus status={this.state.status} />
                                            </div>
                                        </AvGroup>
                                    </AvForm>

                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={this.handleEditSubmit} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Simpan</span>}
                                            {!loading && <span>Simpan</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h4>Ubah Password User</h4>

                                    <AvForm className="needs-validation user-add" noValidate="">
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_bidang" className="mr-sm-2"><strong>Pilih User</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <Select
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    // value={this.state.pilihPaket}
                                                    onChange={this.handleSelectPassword}
                                                    options={
                                                        this.state.dataCompany.users.map((item, index) => (
                                                            {
                                                                key: index,
                                                                value: item.p_user_id,
                                                                label: item.p_user_name + ' - ' + ' (' + item.p_user_email + ')',
                                                            }
                                                        )
                                                        )
                                                    }
                                                    defaultValue={{
                                                        value: '',
                                                        label: 'Pilih User Perusahaan'
                                                    }}
                                                />
                                            </div>
                                        </AvGroup>
                                        <AvGroup style={{ marginBottom: '10px' }}>
                                            <Label for="perusahaan_password" className="mr-sm-2"><strong>Password Baru Perusahaan User *</strong></Label>
                                            <div className="col-md-12" style={{ padding: "inherit" }}>
                                                <AvInput
                                                    style={{ width: "inherit" }}
                                                    type="password"
                                                    name="pw_user_value"
                                                    placeholder="Password baru user.."
                                                    id="pw_user_value"
                                                    onChange={this.handleChange}
                                                    value={this.state.pw_user_value}
                                                />
                                            </div>
                                        </AvGroup>
                                    </AvForm>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-primary" onClick={() => { this.handleEditPasswordSubmit() }} disabled={loading}>
                                            {loading && (
                                                <i
                                                    className="fa fa-refresh fa-spin"
                                                    style={{ marginRight: "5px" }}
                                                />
                                            )}
                                            {loading && <span>Ubah Password</span>}
                                            {!loading && <span>Ubah Password</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Edit
