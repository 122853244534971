import React, { Component,Fragment } from 'react'
import {Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { clear_auth } from '../../../reducers/Auth'
import {API_HOST, IMG_HOST, DEFAULT_IMG} from "../../../helper/host";
import man from '../../../assets/images/dashboard/man.png'
import {requestData} from "../../../helper/request";
export class User_menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            data_user: [],
            data_id:[],
        };

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.props.clear_auth();
    }

    loadDataUser() {
        let form = {
            data: 'data',
        }
        this.props.request("admin/admin-after", form, 'POST')
            .then(response => {
                if (response.success) {
                    this.setState({
                        data_user: response.data.foto,
                        data_id:response.data.id
                    });
                }
            });
    }

    componentDidMount() {
        this.loadDataUser()
    }

    render() {
        return (
            <Fragment>
                    <li className="onhover-dropdown">
                        <div className="media align-items-center">
                            <img className="align-self-center pull-right rounded-circle blur-up lazyloaded" onError={(event)=>{
                                event.target.src = DEFAULT_IMG
                            }}
                                 src={this.state.data_user !== null ? IMG_HOST +'admin/user/' + this.state.data_user : IMG_HOST + "logo-default-user.png"} alt="header-user" style={{width: "50px", height: "50px", objectFit: "cover"}} />
                            <div className="dotted-animation"><span className="animate-circle"></span><span className="main-circle"></span></div>
                        </div>
                        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                            <li><a href={"/users/edit-users/" + this.state.data_id}
                            ><i data-feather="user"></i>Edit Profile</a></li>
                            {/*<li><a href="javascript:void(0)"><i data-feather="mail"></i>Inbox</a></li>*/}
                            {/*<li><a href="javascript:void(0)"><i data-feather="lock"></i>Lock Screen</a></li>*/}
                            {/*<li><a href="javascript:void(0)"><i data-feather="settings"></i>Settings</a></li>*/}
                            <li><a href="/" onClick={this.handleLogout}><i data-feather="log-out"></i>Logout</a></li>
                        </ul>
                    </li>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.Auth.user
})

const mapDispatchToProps = dispatch => ({
    clear_auth: () => dispatch(clear_auth())
})

export default connect(mapStateToProps, mapDispatchToProps)(User_menu);
